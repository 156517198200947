import type {
  CreateTestExecution,
  QueryResult,
  TestExecution,
  TestExecutionStatus,
} from "../@types/sd/testexecutions";
import useApi, { type ApiError } from "../hooks/UseApi";
import useApiMutation from "../hooks/UseApiMutation";

export const GET_TEST_EXECUTIONS_API_NAME = "api:get-test-executions";
export const CREATE_TEST_EXECUTION_API_NAME =
  "api_mutation:create-test-execution";
export const GET_TEST_EXECUTION_API_NAME = "api:get-test-execution";
export const FILTER_TEST_EXECUTIONS_API_NAME = "api:filter-test-executions";
export const GET_SANDBOX_TEST_EXECUTION_API_NAME =
  "api:get-sandbox-test-execution";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 5000,
};

interface QueryOptions {
  isAutoDiff?: boolean;
  skipDeletedTests?: boolean;
  testName?: string;
  orderDir?: "asc" | "desc";
  published?: boolean | "all";
  pageSize?: number;
}

export type WrappedTestExecution = {
  execution: TestExecution;
  cursor: number;
};

export const useGetTestExecutions = (
  queryOptions: QueryOptions,
  options = {}
) => {
  const params = {
    ...queryOptions,
  };

  const queryParams = new URLSearchParams(
    Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => [key, String(value)])
  );

  return useApi<WrappedTestExecution[]>(
    [GET_TEST_EXECUTIONS_API_NAME, params.testName],
    `/api/v2/orgs/:orgName/tests/executions?${queryParams.toString()}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
};

export const useGetTestExecution = (
  testName: string,
  executionName: string,
  options = {}
) =>
  useApi<TestExecution>(
    [GET_TEST_EXECUTION_API_NAME, testName, executionName],
    `/api/v2/orgs/:orgName/tests/${testName}/executions/${executionName}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useCreateTestExecution = (
  onSaved: (data: TestExecution) => void,
  onError: (error: ApiError) => void
) =>
  useApiMutation<TestExecution, CreateTestExecution>(
    CREATE_TEST_EXECUTION_API_NAME,
    "POST",
    [GET_TEST_EXECUTIONS_API_NAME],
    onSaved,
    onError
  );

export const useQuerySandboxTestExecutions = (
  sandboxName: string,
  options = {}
) =>
  useApi<QueryResult[]>(
    [FILTER_TEST_EXECUTIONS_API_NAME, sandboxName],
    `/api/v2/orgs/:orgName/tests/executions?targetSandbox=${sandboxName}&groupBy=test&orderDir=desc`, //    `/api/v2/orgs/:orgName/sandboxes/${sandboxName}/testexecutions`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useGetSandboxTestExecutions = (
  sandboxName: string,
  options = {}
) =>
  useApi<TestExecution[]>(
    [GET_SANDBOX_TEST_EXECUTION_API_NAME, sandboxName],
    `/api/v2/orgs/:orgName/sandboxes/${sandboxName}/testexecutions`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );
