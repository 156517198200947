/* eslint-disable react/jsx-props-no-spreading,react/jsx-key */
import React from "react";
import styled from "styled-components";

import MaUTableBase from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import type { CellValue, IdType } from "react-table";
import { useTable } from "react-table";
import SdTheme from "../../styles/theme";
import styles from "./Table.module.css";
import { getBaseCellProps } from "./utils";

// Styled table with borders on all sides
const MaUTable = styled(MaUTableBase)`
  border-left: 1px solid var(--SIGNADOT-NEUTRAL200);
  border-right: 1px solid var(--SIGNADOT-NEUTRAL200);
  border-bottom: 1px solid var(--SIGNADOT-NEUTRAL200);
`;

interface Column {
  Header: string;
  show?: boolean;
  accessor: string;
}

// For some reason, the type stuff in the Table code ends up trying to match the accessor
// property with the type on the right side of the accessor property in this interface.
// And for SOME reason, it decides that an assignment from string to that type is impossible.
// Even if we made our own type that guaranteed the resulting type from the ternary was a
// string it still marks it as a type error. So we match the exact type in the Table library
// here so that it sees it as a valid assignment.
// We also have to force a cast from the Column type to this "fix" type so that there isn't a
// type error reported from passing the column values to the Table library.
interface FixColumn<DataT> {
  Header: string;
  show?: boolean;
  accessor: keyof DataT extends never ? IdType<DataT> : never;
}

interface Props<DataT> {
  columns: Column[];
  data: DataT[];
  onActionCallback?: (row: Record<IdType<DataT>, CellValue>) => void;
  onRowClickCallback?: (row: DataT) => void;
}

const ClickableRow = styled(TableRow)`
  background-color: ${SdTheme.Table.background};
  :hover {
    background-color: ${SdTheme.Table.hovered};
    cursor: pointer;
  }
`;

const NonClickableRow = styled(TableRow)`
  background-color: ${SdTheme.Table.background};
  :hover {
    background-color: ${SdTheme.Table.hovered};
  }
`;

function Table<DataT extends object>({
  columns,
  data,
  onActionCallback,
  onRowClickCallback,
}: Props<DataT>) {
  const fixColumns = columns as FixColumn<DataT>[];
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<DataT>({
    columns: fixColumns,
    data,
    initialState: {
      hiddenColumns: columns
        .filter((col) => col.show === false)
        .map((col) => col.accessor),
    },
  });

  const Row = onRowClickCallback ? ClickableRow : NonClickableRow;

  // Render the UI for your table
  return (
    <MaUTable {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                className={styles.headerCell}
                size="small"
                {...column.getHeaderProps()}
                style={column.Header === "" ? { width: "10px" } : {}}
              >
                <b>{column.render("Header")}</b>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Row {...row.getRowProps()}>
              {row.cells.map((cell) =>
                cell.value !== null ? (
                  <TableCell
                    className={styles.tableCell}
                    {...getBaseCellProps(cell)}
                    onClick={() => {
                      if (
                        cell.column.Header === "Action" ||
                        cell.column.Header === "Settings"
                      ) {
                        onActionCallback?.(cell.row.values);
                      } else {
                        onRowClickCallback?.(row.original);
                      }
                    }}
                    {...cell.getCellProps()}
                  >
                    {(cell.value && cell.value.component) ||
                      cell.render("Cell")}
                  </TableCell>
                ) : (
                  <TableCell
                    className={styles.tableCell}
                    {...getBaseCellProps(cell)}
                  >
                    {null}
                  </TableCell>
                )
              )}
            </Row>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}

export default Table;
