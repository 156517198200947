import React from "react";
import { Icon, Intent } from "@blueprintjs/core";

interface Props {
  errorMessage?: string;
}

const ValidationError: React.FC<Props> = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        marginBottom: "1em",
        color: Intent.DANGER,
      }}
    >
      <Icon icon="warning-sign" intent={Intent.DANGER} />
      <span>{errorMessage}</span>
    </div>
  );
};

export default ValidationError;
