import React from "react";
import type {
  FilteredViewColumn,
  FilteredViewProps as InnerViewProps,
  FilterViewRowBase,
} from "./FilteredView";
import FilteredView from "./FilteredView";
import { FilteredViewProvider } from "./FilteredViewContext";

export type FilteredViewProps<T extends FilterViewRowBase> = InnerViewProps<T> &
  Omit<React.ComponentProps<typeof FilteredViewProvider>, "children">;
const View = <T extends FilterViewRowBase>({
  rawData,
  filters,
  columns,
  totalWeight,
  pageSize,
  onRowClick,
  title,
  columnsHeaders,
  rowClassName,
  renderRow,
  emptyRowsRender,
}: FilteredViewProps<T>) => {
  if (columnsHeaders && columnsHeaders.length !== columns.length) {
    throw new Error(
      `Number of columns (${columns.length}) does not match the number of column headers (${columnsHeaders.length}).`
    );
  }

  return (
    <FilteredViewProvider rawData={rawData}>
      <FilteredView
        columns={columns}
        title={title}
        totalWeight={totalWeight}
        onRowClick={onRowClick}
        pageSize={pageSize}
        filters={filters}
        columnsHeaders={columnsHeaders}
        rowClassName={rowClassName}
        emptyRowsRender={emptyRowsRender}
        renderRow={renderRow}
      />
    </FilteredViewProvider>
  );
};

export default View;

export type { FilteredViewColumn };
