import type { TestExecution } from "../../../../../@types/sd/testexecutions";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../../../@types/sd/testexecutions.d.ts";
import {
  getFindingsCount,
  type MajorFindingCountLabel,
} from "../../useGetTestExecutionDetails";

export type AggregatedExecutions = {
  failed: number;
  succeeded: number;
  canceled: number;
  running: number;
  pending: number;
};

export type AggregatedDiffs = {
  totalDiff: number;
  count: {
    red: number;
    yellow: number;
    green: number;
  };
  totalExecutionsWithoutDiff: number;
  major: MajorFindingCountLabel; // can be updated if you use this field
};

export type AggregatedChecks = {
  totalChecks: number;
  count: {
    fail: number;
    pass: number;
  };
  totalExecutionsWithoutChecks: number;
};
export const aggregateExecutions = (
  executions: TestExecution[]
): AggregatedExecutions =>
  executions.reduce<AggregatedExecutions>(
    (acc, execution) => {
      switch (execution.status.phase) {
        case TestExecutionStatusPhases.Pending:
          acc.pending += 1;
          break;
        case TestExecutionStatusPhases.InProgress:
          acc.running += 1;
          break;
        case TestExecutionStatusPhases.Succeeded:
          acc.succeeded += 1;
          break;
        case TestExecutionStatusPhases.Canceled:
          acc.canceled += 1;
          break;
        case TestExecutionStatusPhases.Failed:
          acc.failed += 1;
          break;
        default:
          break;
      }
      return acc;
    },
    { failed: 0, succeeded: 0, canceled: 0, running: 0, pending: 0 }
  );

export const aggregateDiffs = (
  executions: TestExecution[]
): AggregatedDiffs =>
  executions.reduce<AggregatedDiffs>(
    (acc, execution) => {
      const trafficDiff = execution.results?.trafficDiff;
      if (!trafficDiff) {
        acc.totalExecutionsWithoutDiff += 1;
        return acc;
      }

      const { count } = getFindingsCount(trafficDiff);
      const { red, yellow, green } = count;
      const totalCount = red + yellow + green;
      if (totalCount === 0) {
        acc.totalExecutionsWithoutDiff += 1;
        return acc;
      }

      acc.totalDiff += totalCount;
      acc.count.red += red;
      acc.count.yellow += yellow;
      acc.count.green += green;
      return acc;
    },
    {
      totalDiff: 0,
      count: { red: 0, yellow: 0, green: 0 },
      totalExecutionsWithoutDiff: 0,
      major: "none",
    }
  );

export const aggregateChecks = (
  executions: TestExecution[]
): AggregatedChecks =>
  executions.reduce<AggregatedChecks>(
    (acc, execution) => {
      const sandbox = execution.results?.checks?.sandbox;
      if (!sandbox) {
        acc.totalExecutionsWithoutChecks += 1;
        return acc;
      }

      const { passed, failed } = sandbox.reduce(
        (prev, curr) => {
          if (curr.errors && curr.errors.length > 0) {
            prev.failed += 1;
          } else {
            prev.passed += 1;
          }
          return prev;
        },
        { passed: 0, failed: 0 }
      );

      acc.count.fail += failed;
      acc.count.pass += passed;
      acc.totalChecks += sandbox.length;

      return acc;
    },
    {
      totalChecks: 0,
      count: { fail: 0, pass: 0 },
      totalExecutionsWithoutChecks: 0,
    }
  );
