import React from "react";
import { Callout, Card } from "@blueprintjs/core";
import moment from "moment";
import { SdHeading1 } from "@components/theming/SdHeading";

import styles from "./Billing.module.css";
import type { Subscription } from "../../@types/sd/billing";
import { useBillingApi } from "@api/BillingApi";
import UsageInfo from "./UsageInfo";
import { ENTERPRISE } from "./Constants";

interface PlanInfoProps {
  subscription?: Subscription;
}

export const PlanInfo: React.FunctionComponent<PlanInfoProps> = ({
  subscription,
}) => {
  const { plan, additionalUnits } = (subscription || {}) as Subscription;
  return (
    <Card className={styles.planInfoCard}>
      <div className={styles.title}>Subscription</div>
      <div className="mb-5">
        <p className={styles.planName}>
          Plan: {plan.displayName}
          {subscription?.trial ? " (Trial) " : ""}
        </p>
        {!!subscription?.endsAt && (
          <p>Ends on {moment(subscription.endsAt).format("LL")}</p>
        )}
      </div>

      {Array.isArray(additionalUnits) && additionalUnits.length > 0 && (
        <div className={styles.additionalUnits}>
          <div className={styles.heading}>Add Ons</div>
          <ul>
            {additionalUnits?.map((unit) => (
              <li key={unit.additionalUnitsType}>
                {unit.displayName}: {unit.total} ({unit.quantity}x{unit.lotSize}
                )
              </li>
            ))}
          </ul>
        </div>
      )}

      {plan.planType === ENTERPRISE ? (
        <p className={styles.help}>
          Please contact{" "}
          <a href="mailto:sales@signadot.com">sales@signadot.com</a> for plan
          &amp; billing
        </p>
      ) : (
        <Callout intent="primary">
          For plan upgrades or management, please reach out to us via{" "}
          <a
            href="https://signadotcommunity.slack.com/join/shared_invite/zt-1estxm8pv-qfiaNfiFFCaW~eUlXsVoEQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            slack
          </a>{" "}
          or{" "}
          <a
            href="mailto:info@signadot.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            email
          </a>
          . You can view our pricing details{" "}
          <a
            href="https://www.signadot.com/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .{" "}
        </Callout>
      )}
    </Card>
  );
};

const Billing: React.FunctionComponent = () => {
  const { data, error } = useBillingApi();
  if (!data || error) {
    return null;
  }
  const { subscription, usage } = data;
  return (
    <>
      <SdHeading1 small lightBackground>
        Billing
      </SdHeading1>
      <PlanInfo subscription={subscription} />
      <UsageInfo usage={usage} />
    </>
  );
};

export default Billing;
