import { NavigateFunction } from "react-router-dom";
import { TiWarning } from "react-icons/ti";
import React from "react";
import type { Test } from "../../../../@types/sd/testspec";
import type { StatusStandard } from "../../../../components/MultiTabsLayout/MultiTabsLayout";

export const getStatus = (
  test: Test,
  isNewTest: boolean,
  navigate: NavigateFunction
): StatusStandard | undefined => {
  const triggers = test.spec.triggers;

  if (!test || isNewTest) return undefined;

  if (triggers) return undefined;

  return {
    getText: "No triggers",
    getColor: "warning",
    icon: <TiWarning />,
    onClick: () => navigate(`/testing/hosted-tests/${test.name}/triggers`),
  };
};
