import React from "react";
import type { TestExecution, TestExecutionStatus } from "../../../../../@types/sd/testexecutions";
import { titleMapping } from "../../../../Testing/TestExecutionDetail/common/ExecutionStatus/ExecutionStatus";
import style from "./TestResultCard.module.css";

type TestResultCardProps = {
  execution: TestExecution;
  children?: React.ReactNode;
};

const TestResultCard = ({ execution, children }: TestResultCardProps) => (
  <div key={execution.name} className={style.testCard}>
    <div className={style.testHeader}>
      <h3>{execution.spec.test}</h3>
      <span className={style.statusBadge}>{titleMapping[execution.status.phase]}</span>
    </div>
    <div className={style.testDetails}>
      <div className={style.testInfo}>
        <span>Run ID: run-{execution.name.slice(0, 8)}</span>
        <span>Duration: 2m 30s</span>
        <span>Last Run: 5 minutes ago</span>
      </div>
      <div className={style.labels}>
        {[
          { key: "github_repo", value: "github.com/org/repo" },
          { key: "published", value: "true" },
          { key: "environment", value: "staging" },
        ].map(({ key, value }) => (
          <span key={key} className={style.label}>
            {key}={value}
          </span>
        ))}
      </div>
      <div className={style.testComponents}>{children}</div>
    </div>
  </div>
);

export default TestResultCard;
