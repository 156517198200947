import React from "react";
import { useGetJobs } from "@api/JobsApi";
import { SdHeading1 } from "@components/theming/SdHeading";
import Loading from "../../../../components/Loading/Loading";
import EmptyJobs from "./EmptyJobs";
import JobsList from "../../../../components/JobsList";

const Jobs = () => {
  const { isSuccess, data: jobs, isLoading } = useGetJobs();
  const sortedJobs = React.useMemo(
    () =>
      (jobs || []).slice().sort((job1, job2) => {
        const d1 = new Date(job1.createdAt);
        const d2 = new Date(job2.createdAt);
        return d2.getTime() - d1.getTime();
      }),
    [jobs]
  );

  if (!isLoading && !isSuccess) {
    return null;
  }

  return (
    <div>
      <div style={{ marginBottom: "40px" }}>
        <SdHeading1 small lightBackground>
          Jobs
        </SdHeading1>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {Array.isArray(sortedJobs) ? (
            <>
              <JobsList
                jobs={sortedJobs.map((job) => ({
                  ...job,
                  id: job.name,
                }))}
              />
            </>
          ) : (
            <EmptyJobs />
          )}
        </>
      )}
    </div>
  );
};

export default Jobs;
