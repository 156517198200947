import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Not a Typescript library, and no types module exists, so we'll ignore the TS error for now.
// @ts-ignore
import rql from "javascript-rql";
import _ from "lodash";
import { SdHeading1, SdHeading5 } from "@components/theming/SdHeading";
import FilterByEventType, { EVENTS_TYPE } from "./FilterByEventType";
import FilterByLabels from "./FilterByLabels";
import FilterByDate from "./FilterByDate";
import FilterSplitBy from "./FilterSplitBy";
import { useAnalyticsApi, useEventsApi } from "@api/AnalyticsApi";
import AnalyticsChart from "./AnalyticsChart";
import EventsTable from "./EventsTable";
import { exportToCsv, getExportFilename } from "./utils";
import SdButton from "@components/theming/SdButton";
import styles from "./Analytics.module.css";

type FilterLabel = {
  key: string;
  value: string;
};

const Analytics: React.FunctionComponent = () => {
  const [selectedEvents, setSelectedEvents] = useState<string[]>(EVENTS_TYPE);
  const [labels, setLabels] = useState<FilterLabel[]>([]);
  const initialDateStart = useMemo(() => {
    const start = new Date();
    start.setDate(1);
    return start;
  }, []);
  const initialDateEnd = useMemo(() => new Date(), []);
  const [selectedDateStart, setSelectedDateStart] = useState(initialDateStart);
  const [selectedDateEnd, setSelectedDateEnd] = useState(initialDateEnd);
  const [selectedSplitBy, setSelectedSplitBy] = useState("Day");
  const lastAnalyticsUpdateRef = useRef<_.DebouncedFunc<() => void>>(null);

  const nullableSetSelectedDateStart = useCallback(
    (d: Date | null) => {
      if (d !== null) {
        setSelectedDateStart(d);
      } else {
        setSelectedDateStart(initialDateStart);
      }
    },
    [initialDateStart, setSelectedDateStart]
  );
  const nullableSetSelectedDateEnd = useCallback(
    (d: Date | null) => {
      if (d !== null) {
        setSelectedDateEnd(d);
      } else {
        setSelectedDateEnd(initialDateEnd);
      }
    },
    [initialDateEnd, setSelectedDateEnd]
  );

  const filters = useMemo(() => {
    const query: Record<string, string> = {};
    for (let i = 0; i < labels.length; i++) {
      const { key, value } = labels[i];
      if (!key || !value) {
        continue;
      }
      query[`labels.${key}`] = value;
    }
    const result = rql(query);
    // If nothing was added into the query object, rql creates an empty string. This prevents
    // the empty filter string from being sent in the API requests.
    if (!result) {
      return undefined;
    }
    return result;
  }, [selectedEvents, labels]);
  const analyticsApi = useAnalyticsApi({
    eventTypes: selectedEvents,
    startTime: selectedDateStart ?? undefined,
    endTime: selectedDateEnd ?? undefined,
    filters,
    interval: selectedSplitBy === "Hour" ? "1h" : "1d",
  });
  const eventsApi = useEventsApi({
    eventTypes: selectedEvents,
    startTime: selectedDateStart,
    endTime: selectedDateEnd,
    filters,
  });

  useEffect(() => {
    lastAnalyticsUpdateRef.current = _.debounce(() => {
      analyticsApi.submit();
      eventsApi.submit();
    }, 600);

    lastAnalyticsUpdateRef.current();

    return () => {
      lastAnalyticsUpdateRef.current?.cancel();
    };
  }, [
    selectedEvents,
    selectedDateEnd,
    selectedDateStart,
    selectedSplitBy,
    filters,
  ]);

  const handleExport = () => {
    const csvData = exportToCsv(eventsApi);
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = getExportFilename(
      selectedEvents,
      selectedDateStart,
      selectedDateEnd
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <main className={styles.container}>
      <header className={styles.header}>
        <SdHeading1 small lightBackground>
          Analytics
        </SdHeading1>
      </header>

      <section className={styles.filtersSection}>
        <SdHeading5 lightBackground>Filters</SdHeading5>
        <div className={styles.filtersGrid}>
          <div className={styles.filterRow}>
            <span className={styles.filterLabel}>Event</span>
            <div className={styles.filterInput}>
              <FilterByEventType
                selectedEvents={selectedEvents}
                onEventItemRemoved={(event) =>
                  setSelectedEvents((events) =>
                    events.filter((e) => e !== event)
                  )
                }
                onEventItemAdded={(event) =>
                  setSelectedEvents((events) => {
                    if (events.includes(event))
                      return events.filter((e) => e !== event);
                    return [...events, event];
                  })
                }
              />
            </div>
          </div>

          <div className={styles.filterRow}>
            <span className={styles.filterLabel}>Labels</span>
            <div className={styles.filterInput}>
              <FilterByLabels labels={labels} setLabels={setLabels} />
            </div>
          </div>

          <div className={styles.filterRow}>
            <span className={styles.filterLabel}>Duration</span>
            <div className={styles.filterInput}>
              <FilterByDate
                selectedDateStart={selectedDateStart}
                setSelectedDateStart={nullableSetSelectedDateStart}
                selectedDateEnd={selectedDateEnd}
                setSelectedDateEnd={nullableSetSelectedDateEnd}
              />
            </div>
          </div>

          <div className={styles.filterRow}>
            <span className={styles.filterLabel}>Split By</span>
            <div className={styles.filterInput}>
              <FilterSplitBy
                splitBy={selectedSplitBy}
                setSplitBy={setSelectedSplitBy}
              />
            </div>
          </div>
        </div>
      </section>

      {!analyticsApi.isLoading &&
        !eventsApi.isLoading &&
        (analyticsApi.data?.groups?.entries?.length ?? 0) === 0 &&
        (eventsApi.data?.events?.length ?? 0) === 0 && (
          <div className={styles.noData}>No event data</div>
        )}
      {(analyticsApi.isLoading ||
        eventsApi.isLoading ||
        (eventsApi.data?.events?.length ?? 0) > 0) && (
        <>
          <header className={styles.resultsHeader}>
            <SdHeading5 lightBackground>
              Showing {eventsApi.data?.events.length} events in total
            </SdHeading5>
            <SdButton
              onClick={handleExport}
              disabled={!eventsApi.data?.events}
              icon="download"
              minimal
            >
              Export CSV
            </SdButton>
          </header>

          <section className={styles.chartSection}>
            <AnalyticsChart
              analyticsApi={analyticsApi}
              selectedSplitBy={selectedSplitBy}
            />
          </section>

          <EventsTable eventsApi={eventsApi} />
        </>
      )}
    </main>
  );
};

export default Analytics;
