import React, { useEffect, useRef, useState } from "react";
import { Icon, Intent } from "@blueprintjs/core";
import type { ImperativePanelHandle } from "react-resizable-panels";
import { Panel, PanelGroup } from "react-resizable-panels";
import BaseEditor from "../../../../components/Editor";
import SdButton from "../../../../components/theming/SdButton";
import type { Test } from "../../../../@types/sd/testspec";
import ClusterSelector from "../../TestSpecs/TestDetail/ClusterSelector";
import { LogPanel, TestNameDialog } from "./components";
import {
  LEFT_PANEL_MIN_SIZE,
  LOGS_PANEL_COLLAPSED_SIZE,
  LOGS_PANEL_DEFAULT_SIZE,
  LOGS_PANEL_MAX_SIZE,
  LOGS_PANEL_MIN_SIZE,
  MAIN_PANEL_NAME,
} from "./constants";
import style from "./SyntheticTestEditor.module.css";
import CustomPanelResizeHandle from "../../../../components/CustomPanelResizeHandle/CustomPanelResizeHandle";
import { SyntheticTestEditorProvider, useSyntheticTestEditor } from "./context";

type SyntheticTestEditorProps = {
  test: Test;
  isExistingTest: boolean;
};

const SyntheticTestEditorContent = () => {
  const {
    test,
    setTest,
    isExistingTest,
    clusterNameTest,
    setClusterNameTest,
    logsMessage,
    showLogPanelContent,
    showSetName,
    onSave,
    handleSaveAndRunTest,
  } = useSyntheticTestEditor();

  const [originalTestScript, setOriginalTestScript] = useState<string>();
  const logPanelRef = useRef<ImperativePanelHandle>(null);

  useEffect(() => {
    if (!test?.spec?.script) {
      return;
    }

    setOriginalTestScript(test.spec.script);
  }, [test]);

  useEffect(() => {
    logPanelRef.current?.resize(
      showLogPanelContent.isOpen ? LOGS_PANEL_DEFAULT_SIZE : LOGS_PANEL_MIN_SIZE
    );
  }, [showLogPanelContent.isOpen]);

  if (test?.spec?.script === undefined) {
    return "no script";
  }

  return (
    <div className={style.wrapper}>
      <TestNameDialog
        isOpen={showSetName.isOpen}
        onCancel={() => {
          showSetName.close();
        }}
        onConfirm={(newTestName: string) => {
          setTest((prev: Test) => ({
            ...prev,
            name: newTestName,
          }));
          showSetName.close();
        }}
      />

      <PanelGroup
        direction="horizontal"
        className={style.wrapper}
        autoSaveId={MAIN_PANEL_NAME}
      >
        <Panel order={1} minSize={LEFT_PANEL_MIN_SIZE}>
          <PanelGroup direction="vertical">
            <Panel order={1}>
              <BaseEditor
                language="python"
                value={test?.spec?.script}
                onChange={(script) =>
                  setTest((t: Test) => ({
                    ...t,
                    spec: {
                      ...t.spec,
                      script: script || "",
                    },
                  }))
                }
                header={
                  <div className="flex justify-between items-center">
                    <div>
                      {(originalTestScript !== test.spec?.script ||
                        !isExistingTest) && (
                        <div className={style.unsave_message}>
                          <Icon icon="warning-sign" intent={Intent.WARNING} />
                          <p>Unsaved changes</p>
                        </div>
                      )}
                    </div>
                    <div className="flex gap-1 justify-end items-end">
                      <ClusterSelector
                        value={clusterNameTest}
                        onChange={setClusterNameTest}
                        unsetInitial
                      />
                      <SdButton
                        icon={<Icon icon="bug" color="white" />}
                        onClick={handleSaveAndRunTest}
                        disabled={!clusterNameTest}
                      >
                        Save & Run
                      </SdButton>
                      <SdButton onClick={onSave}>Save</SdButton>
                    </div>
                  </div>
                }
              />
            </Panel>
            <CustomPanelResizeHandle direction="vertical" />

            <Panel
              order={2}
              maxSize={LOGS_PANEL_MAX_SIZE}
              minSize={LOGS_PANEL_MIN_SIZE}
              collapsedSize={LOGS_PANEL_COLLAPSED_SIZE}
              defaultSize={LOGS_PANEL_DEFAULT_SIZE}
              collapsible
              onCollapse={showLogPanelContent.close}
              onExpand={showLogPanelContent.open}
              ref={logPanelRef}
            >
              <LogPanel
                isCollapsed={!showLogPanelContent.isOpen}
                onOpen={() => {
                  showLogPanelContent.open();
                  logPanelRef.current?.resize(20);
                }}
                logsMessage={logsMessage}
              />
            </Panel>
          </PanelGroup>
        </Panel>
      </PanelGroup>
    </div>
  );
};

const SyntheticTestEditor = ({
  test,
  isExistingTest,
}: SyntheticTestEditorProps) => {
  return (
    <SyntheticTestEditorProvider test={test} isExistingTest={isExistingTest}>
      <SyntheticTestEditorContent />
    </SyntheticTestEditorProvider>
  );
};

export default SyntheticTestEditor;
