import type {
  QueryResult,
  TestExecution,
  TestExecutionStatus,
} from "../../../../@types/sd/testexecutions";

// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../../@types/sd/testexecutions.d.ts";

// eslint-disable-next-line import/prefer-default-export
export const groupByTest = (
  executions: QueryResult[]
): Record<string, TestExecution> => {
  const grouped = {};

  executions.forEach((e) => {
    const execution = e.execution;
    const testName = execution.spec.test;
    if (!grouped[testName]) {
      grouped[testName] = execution;
    }
  });

  return grouped;
};

type groupByTestCompletionReturn = {
  completed: TestExecution[];
  others: TestExecution[];
};

/**
 * Sorts test executions for stable ordering in the UI
 * First by testName, then by name (execution ID)
 */
const sortExecutions = (
  executions: TestExecution[]
): TestExecution[] =>
  [...executions].sort((a, b) => {
    // First sort by testName
    const testNameCompare = a.spec.test.localeCompare(b.spec.test);
    if (testNameCompare !== 0) {
      return testNameCompare;
    }

    // Then sort by execution name/ID for executions with the same test name
    return a.name.localeCompare(b.name);
  });

// eslint-disable-next-line import/prefer-default-export
export const groupByTestCompletion = (
  executions: TestExecution[]
): groupByTestCompletionReturn => {
  const groupedExecutions: groupByTestCompletionReturn = {
    completed: [],
    others: [],
  };

  executions.forEach((execution) => {
    switch (execution.status.phase) {
      case TestExecutionStatusPhases.Succeeded:
        groupedExecutions.completed.push(execution);
        break;
      case TestExecutionStatusPhases.Pending:
      case TestExecutionStatusPhases.InProgress:
      case TestExecutionStatusPhases.Failed:
      case TestExecutionStatusPhases.Canceled:
      default:
        groupedExecutions.others.push(execution);
    }
  });

  // Sort both groups for stable ordering
  groupedExecutions.completed = sortExecutions(groupedExecutions.completed);
  groupedExecutions.others = sortExecutions(groupedExecutions.others);

  return groupedExecutions;
};
