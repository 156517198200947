import React, { useState, useEffect } from "react";
import {
  Card,
  Icon,
  Checkbox,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import { SiGithub } from "react-icons/si";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./VcsIntegration.module.css";
import { SdHeading5 } from "../../../components/theming/SdHeading";
import SdButton from "../../../components/theming/SdButton";
import VcsIntegrationModal from "./modal";
import {
  useGetIntegrationsApi,
  useUpdateIntegrationApi,
} from "./VcsIntegrationApi";
import type { Integration } from "../../../@types/sd/interation";
import Spacer from "../../../components/Util/Util";
import type { ApiError } from "../../../hooks/UseApi";

const toaster = OverlayToaster.create();
const VcsIntegration: React.FunctionComponent = () => {
  const onSuccess = () =>
    toaster.show({
      message: "The integration was updated",
      intent: Intent.SUCCESS,
    });
  const onError = (error: ApiError) => {
    toaster.show({
      message:
        "Error: could not update integration: " + error.response.data.error,
      intent: Intent.DANGER,
    });
  };

  const { data: apiData, error } = useGetIntegrationsApi();
  const [integrations, setIntegrations] = useState<Integration[]>([]);

  // Sync state with API data when it changes
  useEffect(() => {
    if (apiData?.integrations) {
      setIntegrations(apiData.integrations);
    }
  }, [apiData]);

  const updateIntegrationApi = useUpdateIntegrationApi(onSuccess, onError);
  const updateIntegration = (
    integrationID: string,
    reportSandboxStatus: boolean
  ) => {
    updateIntegrationApi.mutate(
      {
        url: `/api/v1/orgs/:orgName/integrations/id/${integrationID}/`,
        data: { reportSandboxStatus },
      },
      {
        onSuccess: () => {
          // update the specific integration in local state
          setIntegrations((prevIntegrations) =>
            prevIntegrations.map((integration) =>
              integration.id === integrationID
                ? { ...integration, reportSandboxStatus }
                : integration
            )
          );
        },
      }
    );
  };

  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Card className={styles.vcsIntegrationContainer}>
        <SdHeading5 lightBackground>Github Integration</SdHeading5>
        <p className={styles.info}>
          Github integration enables linking lifecycle of Pull Requests to
          sandboxes. If your GitHub organization has an IP allow list
          configured, please ensure that <b>54.202.35.48</b> is added to the
          list (the designated public IP address from which the Signadot GitHub
          application will operate).
        </p>
        <div>{error}</div>
        {integrations?.map((integration: Integration) => (
          <ul key={integration.id} className={styles.list}>
            {integration.github?.githubOrg && (
              <li className={styles.listItem}>
                <a
                  href={integration.github.githubInstallationPageURL || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiGithub />
                  {integration.github.githubOrg}
                  {integration.github.appInstalled ? null : (
                    <Popover2>
                      <Tooltip2
                        content="Signadot Github app not installed"
                        intent="none"
                        placement="top"
                        hoverOpenDelay={250}
                      >
                        <Icon icon="warning-sign" />
                      </Tooltip2>
                    </Popover2>
                  )}
                </a>
                <Spacer />
                <Checkbox
                  checked={integration.reportSandboxStatus}
                  onChange={(event) => {
                    updateIntegration(
                      integration.id!,
                      event.currentTarget.checked
                    );
                  }}
                  label="Pull Request Comment Notifications"
                />
              </li>
            )}
          </ul>
        ))}
        {Array.isArray(integrations) && integrations!.length === 0 ? (
          <SdButton
            icon="plus"
            className={styles.button}
            onClick={() => setModalOpen(true)}
          >
            Set up a new integration
          </SdButton>
        ) : null}
      </Card>
      <VcsIntegrationModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default VcsIntegration;
