import React from "react";
import classNames from "classnames";
import styles from "./SidebarLabel.module.css";

type SidebarLabelProps = {
  title: React.ReactNode;
  isCollapsed?: boolean;
};

const SidebarLabel = ({ title, isCollapsed }: SidebarLabelProps) => (
  <div
    className={classNames(styles.label, {
      [styles["label--collapsed"]]: isCollapsed,
    })}
  >
    {!isCollapsed && <span className="text-md">{title}</span>}
  </div>
);

export default SidebarLabel;
