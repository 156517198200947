/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { SiGoogleanalytics, SiKubernetes } from "react-icons/si";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { GiPlug } from "react-icons/gi";
import { TbServerBolt } from "react-icons/tb";
import { GrDocumentTest } from "react-icons/gr";
import { BiSolidCollection } from "react-icons/bi";
import styles from "./Sidebar.module.css";
import NavItem from "./NavItem";
import { useWindowsResizeThreshold } from "../../../hooks/UseWindowsResizeThreshold";
import SidebarSection from "./SidebarSection";
import SidebarLabel from "./SidebarLabel";
import useLocalSavedPreferences, {
  PREFERENCES_NAMES,
} from "../../../hooks/UseLocalSavedPreferences";

const MIN_COLLAPSED_SIDEBAR_PX = 1024;

const ICON_SIZES = {
  COLLAPSED: 20,
  EXPANDED: 16,
} as const;

const getIconSize = (isCollapsed: boolean) =>
  isCollapsed ? ICON_SIZES.COLLAPSED : ICON_SIZES.EXPANDED;

export const Sidebar = () => {
  const {
    values: { [PREFERENCES_NAMES.COLLAPSE_SIDEBAR]: userCollapseSidebar },
    setPreference,
  } = useLocalSavedPreferences();
  const isNarrowScreen = useWindowsResizeThreshold(MIN_COLLAPSED_SIDEBAR_PX);

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (isNarrowScreen) {
      setIsCollapsed(true);
      return;
    }

    setIsCollapsed(userCollapseSidebar);
  }, [isNarrowScreen, userCollapseSidebar]);

  return (
    <>
      {/* Sidebar */}
      <div
        className={`${styles.container} ${
          isCollapsed ? styles.collapsed : ""
        } overflow-auto`}
      >
        <div
          className={styles.collapseToggle}
          onClick={() => {
            setPreference(PREFERENCES_NAMES.COLLAPSE_SIDEBAR, (val) => !val);
          }}
        >
          {isCollapsed ? (
            <Icon icon="chevron-right" size={14} />
          ) : (
            <Icon icon="chevron-left" size={14} />
          )}
        </div>
        <div className={styles.list}>
          <NavItem
            icon="dashboard"
            name="Overview"
            url="/"
            collapsed={isCollapsed}
            match="/"
          />
          <NavItem
            icon="layers"
            name="Sandboxes"
            url="/sandboxes"
            collapsed={isCollapsed}
            match={["/sandboxes", "/sandbox"]}
          />
          <NavItem
            icon="layout"
            name="Route Groups"
            url="/routegroups"
            collapsed={isCollapsed}
          />
          <NavItem
            icon={<BiSolidCollection size={getIconSize(isCollapsed)} />}
            name="Jobs"
            url="/testing/jobs"
            collapsed={isCollapsed}
          />
          <SidebarSection
            title="Smart Tests"
            icon={<GrDocumentTest size={getIconSize(isCollapsed)} />}
            isCollapsed={isCollapsed}
            highlightMatch={(url) =>
              ["/testing/hosted-tests", "/testing/executions"].some((match) =>
                url.startsWith(match)
              )
            }
            defaultOpen
          >
            <NavItem
              icon="chart"
              name="Hosted Tests"
              url="/testing/hosted-tests"
              collapsed={false}
            />
            <NavItem
              icon="comparison"
              name="Executions"
              url="/testing/executions"
              collapsed={false}
            />
          </SidebarSection>
          <SidebarLabel title="Platform" isCollapsed={isCollapsed} />
          <NavItem
            icon={<GiPlug size={getIconSize(isCollapsed)} />}
            name="Resource Plugins"
            url="/resource-plugins"
            collapsed={isCollapsed}
          />
          <NavItem
            icon={<TbServerBolt size={getIconSize(isCollapsed)} />}
            name="Job Runner Groups"
            url="/testing/job-runner-groups"
            collapsed={isCollapsed}
          />
          <NavItem
            icon={<SiKubernetes size={getIconSize(isCollapsed)} />}
            name="Clusters"
            url="/settings/clusters"
            collapsed={isCollapsed}
            match={["/settings/clusters", "/cluster"]}
          />
          <NavItem
            icon={<SiGoogleanalytics size={getIconSize(isCollapsed)} />}
            name="Analytics"
            url="/analytics"
            collapsed={isCollapsed}
          />
          <NavItem
            icon="cog"
            name="Settings"
            url="/settings/global"
            collapsed={isCollapsed}
          />
          <SidebarLabel title="Administration" isCollapsed={isCollapsed} />
          <NavItem
            icon="people"
            name="Users"
            url="/users"
            collapsed={isCollapsed}
          />
          <NavItem
            icon="key"
            name="API Keys"
            url="/settings/apikeys"
            collapsed={isCollapsed}
          />
          <NavItem
            icon="credit-card"
            name="Billing"
            url="/billing"
            collapsed={isCollapsed}
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
