import type { TestExecution } from "@constants/@types/sd/testexecutions";
import React from "react";
import Collapse from "../../TestExecutionDetail/common/Collapse";
import type { CollapseOptionsFunc } from "../../TestExecutionDetail/common/Collapse/Collapse";
import classNames from "classnames";
import ExecutionRow from "../ExecutionRow";
import styles from "./ExecutionCollapse.module.css";

type ExecutionCollapseProps = {
  executions: TestExecution[];
  testName: string;
  onSelect: (execution: TestExecution) => void;
};

type ExecutionCollapseTitleProps = {
  testName: string;
  totalExecutions: number;
  opts: CollapseOptionsFunc;
};

const ExecutionCollapseTitle = ({
  testName,
  totalExecutions,
  opts,
}: ExecutionCollapseTitleProps) => (
  <div className={styles.executionCollapseTitle}>
    <Collapse.CollapseButton
      isExpanded={opts.isExpanded}
      onToggle={opts.onToggle}
      className={classNames({
        [styles.hideCollapseTitle]: totalExecutions === 0,
      })}
    />
    <b>{testName}</b>
    <span className={styles.label}>{totalExecutions}</span>
  </div>
);

const ExecutionCollapse = ({
  executions,
  testName,
  onSelect,
}: ExecutionCollapseProps) => (
  <Collapse
    title={(opts) => (
      <ExecutionCollapseTitle
        totalExecutions={executions.length}
        testName={testName}
        opts={opts}
      />
    )}
    show
    defaultOpen={false}
    headerStyle={styles.executionCollapseHeader}
  >
    {executions.map((execution) => (
      <ExecutionRow
        key={execution.name}
        execution={execution}
        onSelect={onSelect}
      />
    ))}
  </Collapse>
);

export default ExecutionCollapse;
