import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Synthetics.module.css";
import { filterTestSpecs, canRunSmartTests } from "../TestSpecs/utils";
import SdButton from "../../../components/theming/SdButton";
import { useGetTests } from "../../../api/TestsApi";
import EmptySynthetics from "./EmptySynthetics/EmptySynthetics";
import TestView from "../TestSpecs/ListTests/component/TestView/TestView";
import type { Test } from "../../../@types/sd/testspec";
import { SdHeading1 } from "../../../components/theming/SdHeading";
import SdInput from "../../../components/theming/SdInput";
import { useManagedRunnerGroups } from "../../../api/ManagedRunnerGroupsApi";
import Loading from "../../../components/Loading/Loading";

const getTestsWithID = (tests: Test[]) =>
  tests.map((t) => ({ ...t, id: t.name }));

const Synthetics = () => {
  const [filterText, setFilterText] = React.useState<string>("");
  const { isSuccess, data } = useGetTests();
  const filteredList = React.useMemo(
    () => filterTestSpecs(filterText, data),
    [data, isSuccess, filterText]
  );

  const managedRgs = useManagedRunnerGroups();
  const navigate = useNavigate();

  if (!isSuccess) {
    return null;
  }

  if (managedRgs.isLoading) {
    return <Loading />;
  }

  const hasManagedRG = canRunSmartTests(managedRgs.data);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SdHeading1 small lightBackground>
          Hosted Tests
        </SdHeading1>
        {hasManagedRG && (
          <SdButton
            onClick={() => {
              navigate(`/testing/hosted-tests/editor`);
            }}
            icon="plus"
          >
            New Hosted Test
          </SdButton>
        )}
      </div>
      {data.length > 0 && hasManagedRG ? (
        <>
          <div>
            <SdInput
              name="search-field"
              type="text"
              placeholder="Type to search ..."
              className="bp4-input bp4-fill bp4-medium mb-4 mt-10"
              onChange={(e) => setFilterText(e.target.value)}
            />
            <TestView
              tests={getTestsWithID(filteredList)}
              onSelected={(t) => navigate(`/testing/hosted-tests/${t.name}`)}
            />
          </div>
        </>
      ) : (
        <EmptySynthetics />
      )}
    </div>
  );
};

export default Synthetics;
