import React from "react";
import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "./TestCollapseTitle.module.css";
import type { CollapseOptionsFunc } from "../Collapse/Collapse";
import { getExtraDetailsForRendering } from "./utils";
import type { TestCollapseWithTestType } from "./utils";
import type { TestExecutionStatusPhases } from "../../../../../@types/sd/testexecutions";

export type TestCollapseTitleProps = {
  testType: "diff" | "checks";
  collapseFuncOpts: CollapseOptionsFunc;
  testName: string;
  executionStatus: TestExecutionStatusPhases;
} & TestCollapseWithTestType;

export type BaseTestCollapseTitleProps = {
  title: string;
  description: string;
  icon: React.ComponentProps<typeof Icon>["icon"];
  collapseFuncOpts: CollapseOptionsFunc;
  className?: string;
  hideCollapse?: boolean;
};

export const BaseTestCollapseTitle = ({
  title,
  description,
  icon,
  collapseFuncOpts,
  className,
  hideCollapse,
}: BaseTestCollapseTitleProps) => (
  <div className={styles.wrapper}>
    <div className={styles.left}>
      <button
        onClick={collapseFuncOpts.onToggle}
        className={classNames(styles.button, {
          [styles.hide_collapse]: hideCollapse,
        })}
        type="button"
        aria-label={`Toggle ${title} section`}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="currentColor"
          className={styles.icon}
          style={{
            transform: collapseFuncOpts.isExpanded
              ? "rotate(180deg)"
              : "rotate(270deg)",
          }}
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <span>{title}</span>
    </div>
    <div className={styles.right}>
      <span>{description}</span>
      <Icon icon={icon} className={className} />
    </div>
  </div>
);

export const TestCollapseTitle = (props: TestCollapseTitleProps) => {
  const { testType, collapseFuncOpts } = props;
  const renderingData = getExtraDetailsForRendering(props);

  return (
    <BaseTestCollapseTitle
      title={testType === "diff" ? "Diff" : "Check"}
      description={renderingData.description}
      icon={renderingData.icon.icon}
      className={renderingData.icon.className}
      collapseFuncOpts={collapseFuncOpts}
    />
  );
};
