import React from "react";
import pluralize from "pluralize";
import { Icon } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "./CapturesInfo.module.css";
import type { TestExecution } from "../../../../../../@types/sd/testexecutions";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../../../../@types/sd/testexecutions.d.ts";
import { getFindingsCount } from "../../../../TestExecutionDetail/useGetTestExecutionDetails";
import { getIconStyles } from "../GeneralInfo/GeneralInfo";
import CategoryIcon from "../../../../TestExecutionDetail/common/CategoryIcon/CategoryIcon";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../../../../@types/sd/findings.d.ts";

interface Props {
  execution: TestExecution;
}

const DEFAULT_CAT = DiffCat.Red;

const getTrafficDiffSummary = (execution: TestExecution): React.ReactNode => {
  const trafficDiff = execution.results?.trafficDiff;
  if (!trafficDiff)
    return (
      <div className={classNames(styles.result_row, styles.resultRowHidden)}>
        <CategoryIcon category={DEFAULT_CAT} iconSize={16} />

        <span>no differences</span>
      </div>
    );

  const { major, count } = getFindingsCount(trafficDiff);
  const total = count.red + count.green + count.yellow;
  if (total === 0 || !major?.label)
    return (
      <div className={classNames(styles.result_row, styles.resultRowHidden)}>
        <CategoryIcon category={DEFAULT_CAT} iconSize={16} />

        <span>no differences</span>
      </div>
    );

  let category: DiffCat;
  switch (major.label) {
    case "high":
      category = DiffCat.Red;
      break;
    case "medium":
      category = DiffCat.Yellow;
      break;
    case "low":
      category = DiffCat.Green;
      break;
    default:
      category = DiffCat.Red;
  }

  return (
    <div className={styles.result_row}>
      <CategoryIcon
        category={category}
        iconSize={category === DiffCat.Green ? 16 : 13}
      />

      <span>
        diff found {major.count} {major.label} relevance{" "}
        {pluralize("issue", major.count)}
      </span>
    </div>
  );
};

const getCheckSummary = (execution: TestExecution): React.ReactNode => {
  const checks = execution.results?.checks;
  if (!checks)
    return (
      <div className={classNames(styles.result_row, styles.resultRowHidden)}>
        <CategoryIcon category={DEFAULT_CAT} iconSize={13} />
        <span>no checks</span>
      </div>
    );

  const sandbox = checks.sandbox;
  if (!sandbox)
    return (
      <div className={classNames(styles.result_row, styles.resultRowHidden)}>
        <CategoryIcon category={DEFAULT_CAT} iconSize={13} />
        <span>no checks</span>
      </div>
    );

  const total = sandbox.reduce(
    (previousValue, currentValue) => {
      if (currentValue.errors && currentValue.errors.length > 0) {
        return {
          ...previousValue,
          failed: previousValue.failed + 1,
        };
      }

      return {
        ...previousValue,
        passed: previousValue.passed + 1,
      };
    },
    { passed: 0, failed: 0 }
  );

  if (total.failed + total.passed === 0) return undefined;

  const testIsFailed = total.failed > 0;

  return (
    <div className={styles.result_row}>
      <Icon
        icon={testIsFailed ? "cross-circle" : "tick-circle"}
        size={12}
        className={classNames({
          [styles.failed]: testIsFailed,
          [styles.passed]: !testIsFailed,
        })}
      />
      <span>
        {testIsFailed
          ? `${total.failed} ${pluralize("check", total.failed)} failed`
          : "all checks passed"}
      </span>
    </div>
  );
};

const STATUS_MAP = {
  [TestExecutionStatusPhases.Failed]: "Run failed",
  [TestExecutionStatusPhases.Succeeded]: "Run completed successfully",
  [TestExecutionStatusPhases.Canceled]: "Run canceled",
  [TestExecutionStatusPhases.InProgress]: "Running",
  [TestExecutionStatusPhases.Pending]: "Pending",
};

const CapturesInfo: React.FC<Props> = ({ execution }) => {
  const trafficDiffSummary = getTrafficDiffSummary(execution);
  const checkSummary = getCheckSummary(execution);

  return (
    <div className={styles.items}>
      <div className={styles.result_row}>
        <Icon {...getIconStyles(execution, 12)} />
        {STATUS_MAP[execution.status.phase]}
      </div>
      {trafficDiffSummary && trafficDiffSummary}
      {checkSummary && checkSummary}
    </div>
  );
};

export default CapturesInfo;
