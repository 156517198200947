import React from "react";
import { Collapse, Icon } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import styles from "./SidebarSection.module.css";
import useDisclosure from "../../../../hooks/UseDisclosure";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
type SidebarSectionProps = {
  title: React.ReactNode;
  icon: React.ComponentProps<typeof Icon>["icon"];
  children: React.ReactNode | React.ReactNode[];
  defaultOpen?: boolean;
  isCollapsed?: boolean;
  spacing?: {
    top?: string;
    bottom?: string;
  };
  highlightMatch?: string | string[] | ((pathname: string) => boolean);
};

const SidebarSection = ({
  title,
  icon,
  children,
  defaultOpen,
  isCollapsed,
  spacing = { top: "0", bottom: "0" },
  highlightMatch,
}: SidebarSectionProps) => {
  const { isOpen, toggle } = useDisclosure(defaultOpen);

  const location = useLocation();
  const isSelected = React.useMemo(() => {
    if (typeof highlightMatch === "string")
      return location.pathname === highlightMatch;
    if (Array.isArray(highlightMatch))
      return highlightMatch.some((m) => location.pathname.startsWith(m));
    if (typeof highlightMatch === "function")
      return highlightMatch(location.pathname);
    return false;
  }, [highlightMatch, location.pathname]);

  const content = (
    <div className={styles.content}>
      <Collapse isOpen={isOpen || isCollapsed}>{children}</Collapse>
    </div>
  );

  const titleContent = (
    <>
      <Icon icon={isOpen ? "chevron-up" : "chevron-down"} className="mx-3" />
      <Icon icon={icon} className="mx-3" />
      <div className="mx-3 text-md">{title}</div>
    </>
  );

  return (
    <div
      className={classNames(styles.section, {
        [styles.collapsed]: isCollapsed,
        [styles.selected]: isSelected,
      })}
      style={{
        marginTop: spacing.top,
        marginBottom: spacing.bottom,
      }}
      data-is-collapsed={isCollapsed}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        onClick={!isCollapsed ? toggle : undefined}
        className={classNames(styles.title, { [styles.selected]: isSelected })}
      >
        {isCollapsed ? (
          <Popover2
            content={content}
            minimal
            interactionKind="hover"
            hoverOpenDelay={100}
            hoverCloseDelay={100}
            className={styles.onlyIcon}
            portalClassName={styles.onlyIcon}
          >
            <div className={styles.onlyIcon}>
              <Icon icon={icon} />
            </div>
          </Popover2>
        ) : (
          titleContent
        )}
      </div>
      {!isCollapsed && content}
    </div>
  );
};

export default SidebarSection;
