import type { ReactNode } from "react";
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { usePostHog } from "posthog-js/react";
import { useQuery } from "react-query";
import axios from "axios";
import EmailNotVerifiedError from "../components/Auth/EmailNotVerified";

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
}

interface Org {
  name: string;
  displayName: string;
  billingTier: number;
  user: User;
  enabledFeatureFlags?: Record<string, boolean>;
}

const CREATE_ORG = "createOrg";

interface UserFlags {
  [CREATE_ORG]: boolean;
}

interface AuthState {
  loading: boolean;
  error: string | null;
  AuthToken: string;
  user?: User;
  org?: Org;
  userFlags?: UserFlags;
  enabledFeatureFlags?: Record<string, boolean>;
  needsEmailVerification?: boolean;
}

type AuthContextValue = AuthState;

const initialState: AuthState = {
  loading: true,
  error: null,
  AuthToken: "",
};

const AuthContext = React.createContext<AuthContextValue>(initialState);

interface Props {
  children?: ReactNode;
}

const fetchOrgs = async (
  token: string
): Promise<{
  user: User;
  orgs: Org[];
  userFlags: UserFlags;
  featureFlags: Record<string, boolean>;
}> => {
  try {
    const { data } = await axios.get(
      `${process.env.VITE_API_BASE_URL}/api/v1/orgs/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    throw new Error("Error fetching organization data");
  }
};

type EmailVerificationState = "required" | "skip" | "verified";

const needsEmailVerification = (user?: {
  sub?: string;
  email_verified?: boolean;
}): EmailVerificationState => {
  // Check if the user is authenticated with SSO.
  // If so, skip email verification.
  const isSSOAuth = user?.sub?.startsWith("oidc|workos-sso-");
  if (isSSOAuth) {
    return "skip";
  }

  const url = new URL(window.location.href);
  const codeParam = url.searchParams.get("code");
  const emailVerified = url.searchParams.get("email_verified");

  // If query string indicates we've already verified, trigger login and skip verification
  if (codeParam === "already-verified" || emailVerified === "true") {
    return "verified";
  }

  // If user is present and their email is not verified, return required
  if (user && !user.email_verified) {
    return "required";
  }

  return "skip";
};

export function AuthContextProvider({ children }: Props) {
  const [authState, setAuthState] = useState<AuthState>(initialState);

  const posthog = usePostHog();
  const { getAccessTokenSilently, isAuthenticated, user, loginWithRedirect } =
    useAuth0();
  const verificationState = needsEmailVerification(user);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // If email verification is required, set the flag
    if (verificationState === "required") {
      setAuthState((prev) => ({
        ...prev,
        loading: false,
        needsEmailVerification: true,
      }));
      return;
    }

    // If verification state is "verified", trigger login redirect
    if (verificationState === "verified") {
      loginWithRedirect();
      return;
    }

    async function fetchToken() {
      try {
        const token = await getAccessTokenSilently();
        setAuthState((prev) => ({
          ...prev,
          AuthToken: token,
          loading: true, // We'll consider ourselves loading if we are about to fetch user/org
        }));
      } catch (e: any) {
        setAuthState((prev) => ({
          ...prev,
          error: e.message ?? "Error retrieving token",
          loading: false,
        }));
      }
    }

    fetchToken();
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    verificationState,
    loginWithRedirect,
  ]);

  const {
    data,
    error: apiError,
    isLoading: apiLoading,
  } = useQuery({
    queryKey: ["auth", "orgs"],
    queryFn: () => fetchOrgs(authState.AuthToken),
    // Only enable this query if we have a valid token and email is verified
    enabled: !!authState.AuthToken && verificationState !== "required",
  });

  useEffect(() => {
    if (apiLoading) {
      setAuthState((prev) => ({ ...prev, loading: true }));
      return;
    }

    if (apiError) {
      setAuthState((prev) => ({
        ...prev,
        error: (apiError as Error)?.message ?? "Unknown error",
        loading: false,
      }));
      return;
    }

    if (data) {
      const { user, orgs, userFlags, featureFlags } = data;
      const firstOrg: Org | undefined = orgs?.[0];

      // If this user is not part of any org, but does not have the createOrg flag, throw an error
      if (!userFlags?.createOrg && !firstOrg) {
        setAuthState((prev) => ({
          ...prev,
          error: "User does not belong to a valid org",
          loading: false,
        }));
        return;
      }

      // --- PostHog logic ---
      const posthogID = posthog?.get_distinct_id();
      if (user && posthogID && posthogID.indexOf("@") === -1) {
        posthog?.identify(user.email);
      }

      if (
        firstOrg &&
        posthog &&
        Object.keys(posthog.getGroups()).length === 0
      ) {
        // Register the org on PostHog if missing
        posthog?.group("org", firstOrg.name, {
          name: firstOrg.name,
          displayName: firstOrg.displayName,
        });
      }

      // --- Self-service pages logic ---
      const isOnNewUserPage =
        window.location.pathname.includes("users/new") ||
        window.location.pathname.includes("orgs/setup");

      if (userFlags?.createOrg) {
        if (!isOnNewUserPage) {
          // ensure user is redirected to new org creation flow
          window.location.href = "/users/new";
          return;
        }
      } else if (isOnNewUserPage) {
        // user shouldn't be here
        window.location.href = "/";
        return;
      }

      setAuthState({
        loading: false,
        error: null,
        AuthToken: authState.AuthToken,
        user,
        org: firstOrg,
        userFlags,
        enabledFeatureFlags: featureFlags,
      });
    }
  }, [apiLoading, data, apiError, posthog, authState.AuthToken]);

  return (
    <AuthContext.Provider value={authState}>
      {authState.needsEmailVerification ? <EmailNotVerifiedError /> : children}
    </AuthContext.Provider>
  );
}

// 5. Export a simple `useAuth()` hook for consuming the context
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
}
