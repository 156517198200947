import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import styles from "./EmptySynthetics.module.css";

const EmptySynthetics = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Icon icon="chart" size={48} className={styles.icon} />
        <h2>No Hosted Tests Yet</h2>
        <p>
          Get started by creating your first hosted test. Hosted tests
          help you monitor your application's performance and availability.
        </p>
        <button
          className={styles.button}
          onClick={() => navigate("/testing/hosted-tests/create")}
        >
          Create Hosted Test
        </button>
      </div>
    </div>
  );
};

export default EmptySynthetics;
