import React from "react";
import { Button } from "@blueprintjs/core";
import Interstitial from "../Interstitial";

const EmailNotVerifiedError = () => {
  const handleSignOut = () => {
    window.location.href = "/signout";
  };

  return (
    <Interstitial>
      {/* Content Section */}
      <h1 className="text-2xl font-semibold text-gray-800 mb-4 text-center">
        Email Verification Required
      </h1>
      <div className="text-center mb-6">
        <p className="text-gray-600 leading-relaxed">
          Please verify your email address to continue. Check your inbox for the
          verification link.
        </p>
        <p className="text-gray-500 text-sm mt-4">
          Can&apos;t find the email? Check your spam or junk folder.
        </p>
      </div>

      {/* Visual Divider */}
      <div className="w-full border-t border-gray-100 my-6" />

      {/* Help Text */}
      <p className="text-gray-400 text-sm text-center mb-6">
        Once verified, you&apos;ll be automatically redirected to continue.
      </p>

      {/* Sign Out Button */}
      <Button icon="log-out" onClick={handleSignOut} minimal className="mt-2">
        Sign Out
      </Button>
    </Interstitial>
  );
};

export default EmailNotVerifiedError;
