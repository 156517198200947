import React from "react";
import CreateFirst from "@components/CreateFirst";
import styles from "./EmptyExecutions.module.css";

const EmptyExecutions = () => (
  <div className={styles.container}>
    <CreateFirst
      icon="lab-test"
      message={
        <p>
          No test executions found. You can start running tests with the
          Signadot CLI. Refer to the
          <a
            href="https://www.signadot.com/docs/reference/tests/spec"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            documentation
          </a>{" "}
          for details.
        </p>
      }
    />
  </div>
);

export default EmptyExecutions;
