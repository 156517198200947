import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Dialog,
  Elevation,
  FormGroup,
  Intent,
  OverlayToaster,
} from "@blueprintjs/core";
import _ from "lodash";
import { IconNames } from "@blueprintjs/icons";
import useApi from "@hooks/UseApi";
import useApiMutation from "@hooks/UseApiMutation";
import Loading from "@components/Loading/Loading";
import { getTimeAgoElement } from "@utils/Util";
import PaginatedTable from "@components/PaginatedTable/PaginatedTable";
import OneTimeTokenDialog from "@components/OneTimeTokenDialog/OneTimeTokenDialog";
import CreateFirst from "@components/CreateFirst";
import { SdHeading1 } from "@components/theming/SdHeading";
import SdButton from "@components/theming/SdButton";
import { SdInputGroup } from "@components/theming/SdInput";

interface ApiKey {
  id: string;
  description: string;
  maskedValue: string;
  createdAt: string;
}

interface ApiKeysResponse {
  apiKeys: ApiKey[];
}

interface CreateApiKeyResponse {
  token?: string;
}

interface FormattedApiKey
  extends Omit<ApiKey, "maskedValue" | "createdAt" | "icon"> {
  maskedValue: string;
  createdAt: React.ReactNode;
  icon: React.ReactNode;
}

const toaster = OverlayToaster.create();

const ApiKeys = () => {
  // state for the modal for creating API keys.
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  // state for the modal for confirming deletion.
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<null | {
    id: string;
    description: string;
  }>(null);

  // state for the description of a new api key.
  const [description, setDescription] = useState("");

  // states for the token dialog
  const [showTokenDialog, setShowTokenDialog] = useState(false);
  const [tokenValue, setTokenValue] = useState("");

  const showCreateApiKeyModal = () => setCreateModalOpen(true);
  const hideCreateApiKeyModal = () => setCreateModalOpen(false);

  const showToken = (token?: string) => {
    if (!token) return;

    setTokenValue(token);
    setShowTokenDialog(true);
  };

  const { isLoading, isIdle, data, error } = useApi<ApiKeysResponse>(
    "apikeys",
    `/api/v1/orgs/:orgName/apikeys`
  );
  const mutation = useApiMutation<CreateApiKeyResponse>(
    "create_api_key",
    "POST",
    ["apikeys"] /* invalidation of get query output */,
    (response) => response && response.token && showToken(response.token)
  );
  const deletion = useApiMutation(
    "delete_api_key",
    "DELETE",
    ["apikeys"] /* invalidation of get query output */
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        search: true,
        show: false,
      },
      {
        Header: "Description",
        accessor: "description",
        search: true,
      },
      {
        Header: "Key",
        accessor: "maskedValue",
      },
      {
        Header: "Created",
        accessor: "createdAt",
      },
      {
        Header: "Action",
        accessor: "icon",
      },
    ],
    []
  );

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (error) {
    return <>Error: {error}</>;
  }

  const formatApiKeys = (apiKeys: ApiKey[]): FormattedApiKey[] => {
    if (apiKeys === null) {
      return [];
    }
    return apiKeys.map((apiKey) => ({
      ...apiKey,
      maskedValue: `${apiKey.maskedValue}...`,
      createdAt: getTimeAgoElement(apiKey.createdAt),
      icon: (
        <Button
          minimal
          icon="trash"
          onClick={() => {
            setSelectedKey({
              id: apiKey.id,
              description: apiKey.description,
            });
            setDeleteModalOpen(true);
          }}
        />
      ),
    }));
  };

  const apiKeys = formatApiKeys(data!.apiKeys);

  // show success / failure messages for API calls.
  if (mutation.error) {
    toaster.show({
      message: _.get(mutation.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    mutation.reset();
  } else if (mutation.isSuccess) {
    toaster.show({ message: "API Key Added", intent: Intent.SUCCESS });
    mutation.reset();
  }

  if (deletion.error) {
    toaster.show({
      message: _.get(deletion.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deletion.reset();
  } else if (deletion.isSuccess) {
    toaster.show({ message: "API Key Deleted", intent: Intent.SUCCESS });
    deletion.reset();
  }

  return (
    <>
      <SdHeading1 small lightBackground>
        API Keys
      </SdHeading1>
      <br />

      {/* Dialog to display the newly generated API key once */}
      <OneTimeTokenDialog
        title="API Key"
        token={tokenValue}
        isDialogOpen={showTokenDialog}
        onClose={() => {
          setShowTokenDialog(false);
          setTokenValue("");
        }}
      />

      {/* Main Table */}
      {(Array.isArray(apiKeys) && apiKeys.length > 0 && (
        <>
          <PaginatedTable columns={columns} data={apiKeys} />
          <br />
          <SdButton icon="plus" onClick={() => showCreateApiKeyModal()}>
            Create API Key
          </SdButton>
        </>
      )) || (
        <CreateFirst
          icon={IconNames.KEY}
          message="Create your first API Key"
          buttonLabel="Create API Key"
          onClick={showCreateApiKeyModal}
        />
      )}

      {/* Modal Dialog to confirm deletion */}
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Key"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={isDeleteModalOpen}
        onCancel={() => {
          setSelectedKey(null);
          setDeleteModalOpen(false);
        }}
        onConfirm={() => {
          const { id } = selectedKey!;
          deletion.mutate({
            data: {},
            url: `/api/v1/orgs/:orgName/apikeys/${id}`,
          });
          setSelectedKey(null);
          setDeleteModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the key:{" "}
          <b>{selectedKey?.description}</b>?
        </p>
      </Alert>

      {/* Modal Dialog for creating API Key */}
      {isCreateModalOpen && (
        <Dialog
          style={{ height: "100%" }}
          icon="info-sign"
          title="Add API Key"
          isOpen={isCreateModalOpen}
          onClose={() => {
            mutation.reset();
            setDescription("");
            hideCreateApiKeyModal();
          }}
        >
          <Card
            style={{ marginBottom: "-20px" }}
            interactive
            elevation={Elevation.TWO}
          >
            <div>
              <p>
                Add a description for your API Key. You can use an API Key to
                authenticate the CLI/SDK and to access{" "}
                <a
                  href="https://www.signadot.com/docs/reference/sandboxes/preview-urls"
                  target="_blank"
                  rel="noreferrer"
                >
                  Sandbox Preview URLs
                </a>{" "}
                programmatically
              </p>
              <FormGroup>
                <SdInputGroup
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(evt) => setDescription(evt.target.value)}
                />
                <br />
                <SdButton
                  icon="plus"
                  onClick={() => {
                    mutation.mutate({
                      data: { description: description },
                      url: `/api/v1/orgs/:orgName/apikeys`,
                    });
                    hideCreateApiKeyModal();
                  }}
                >
                  Add
                </SdButton>
              </FormGroup>
            </div>
          </Card>
        </Dialog>
      )}
    </>
  );
};

export default ApiKeys;
