import React, { useEffect, useMemo, useState } from "react";
import type { WrappedTestExecution } from "@api/TestExecutionsApi";
import { useGetTestExecutions } from "@api/TestExecutionsApi";
import { SdHeading1 } from "@components/theming/SdHeading";
import Loading from "@components/Loading/Loading";
import type { TestExecution } from "@constants/@types/sd/testexecutions";
import SdInput from "@components/theming/SdInput";
import styles from "./Executions.module.css";
import EmptyExecutions from "./EmptyExecutions";
import ExecutionCollapse from "./ExecutionCollapse";

const groupExecutionsByTest = (executions: TestExecution[]) =>
  executions.reduce((acc: { [key: string]: TestExecution[] }, execution) => {
    const test = execution.computedTestName;
    acc[test] = acc[test] || [];
    acc[test].push(execution);
    return acc;
  }, {});

const Executions = () => {
  const {
    isSuccess,
    data: executions,
    isLoading,
  } = useGetTestExecutions({
    orderDir: "desc",
    pageSize: 200,
    skipDeletedTests: true,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<WrappedTestExecution[]>([]);

  useEffect(() => {
    if (executions) {
      const filtered = executions.filter((execution) => {
        if (!searchTerm) return true;

        const testName = execution.execution.spec.test || "";
        const embeddedTestName =
          execution.execution.spec.embeddedSpec?.testName || "";
        const sandbox =
          execution.execution.spec.executionContext?.routing?.sandbox || "";
        const searchLower = searchTerm.toLowerCase();

        return (
          testName.toLowerCase().includes(searchLower) ||
          embeddedTestName.toLowerCase().includes(searchLower) ||
          sandbox.toLowerCase().includes(searchLower)
        );
      });
      setFilteredData(filtered);
    }
  }, [executions, searchTerm]);

  const groupedExecutions = useMemo(() => {
    if (!filteredData) return {};

    const formattedExecutions: TestExecution[] = filteredData.map((e) => {
      let computedTestName = e.execution.spec.test;
      if (!computedTestName || computedTestName.length === 0) {
        computedTestName =
          e.execution.spec.embeddedSpec?.testName || "Unnamed Test";
      }

      return {
        ...e.execution,
        computedTestName,
      };
    });

    return groupExecutionsByTest(formattedExecutions);
  }, [filteredData]);

  if (!isLoading && !isSuccess) {
    return null;
  }

  return (
    <div>
      <div style={{ marginBottom: "40px" }}>
        <SdHeading1 small lightBackground>
          Executions
        </SdHeading1>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.container}>
          <SdInput
            name="search-field"
            type="text"
            placeholder="Search by test name or sandbox..."
            className="bp4-input bp4-fill bp4-medium mb-4"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {Array.isArray(filteredData) && filteredData.length > 0 ? (
            <div>
              {Object.keys(groupedExecutions).map((testName) => (
                <ExecutionCollapse
                  key={testName}
                  executions={groupedExecutions[testName] || []}
                  testName={testName}
                  onSelect={(execution) =>
                    window.open(
                      `/testing/tests/${execution.spec.test}/executions/${execution.name}`,
                      "_blank"
                    )
                  }
                />
              ))}
            </div>
          ) : (
            <EmptyExecutions />
          )}
        </div>
      )}
    </div>
  );
};

export default function ExecutionsPage() {
  return <Executions />;
}
