import React from "react";
import { statusMap } from "./statusCodeDescription";
import classNames from "classnames";
import styles from "./StatusCodeDiff.module.css";

const getStatusCategory = (
  status: number
): "info" | "success" | "redirect" | "error" =>
  statusMap.get(status)?.category || "info";

const StatusBadge: React.FC<{ status: number }> = ({ status }) => {
  const category = getStatusCategory(status);
  const badgeClass = classNames(styles.badge, {
    [styles.greenBackground]: category === "success",
    [styles.redBackground]: category === "error",
    [styles.yellowBackground]: category === "redirect",
    [styles.blueBackground]: category === "info",
  });

  return <span className={`${styles.badge} ${badgeClass}`}>{status}</span>;
};

export default StatusBadge;
