import React from "react";
import {
  FormGroup,
  InputGroup,
  Intent,
  OverlayToaster,
  Spinner,
} from "@blueprintjs/core";
import logo from "../../components/Interstitial/logo";
import SdButton from "../../components/theming/SdButton";
import { useCreateOrgApi } from "../../api/SetupApi";

const toaster = OverlayToaster.create();

const CreateOrgInterstitial = () => {
  const [orgDisplayName, setOrgDisplayName] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);

  const formReady = () =>
    orgDisplayName && firstName && lastName && acceptTerms;

  const [createOrg, createOrgApi] = useCreateOrgApi();
  const handleCreate = () => {
    setSubmitting(true);
    createOrg({
      orgDisplayName,
      firstName,
      lastName,
    });
  };

  if (createOrgApi.error) {
    toaster.show({
      message: createOrgApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createOrgApi.reset();
    setSubmitting(false);
  } else if (createOrgApi.isSuccess) {
    toaster.show({
      message: "Organization created",
      intent: Intent.SUCCESS,
    });
    window.location.href = "/";
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center max-w-md mx-auto p-8 bg-blue-50/80 backdrop-blur-sm rounded-xl shadow-md border border-blue-200">
        <div className="mb-6">
          <img src={logo} alt="Signadot" className="w-16 h-16" />
        </div>
        <h1 className="text-2xl font-semibold text-gray-800 mb-6">
          Set up your organization
        </h1>
        <div className="w-full space-y-4">
          <FormGroup
            label="Organization Name"
            labelFor="org-name"
            className="flex flex-col"
          >
            <InputGroup
              id="org-name"
              value={orgDisplayName}
              onChange={(e) => setOrgDisplayName(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Your First Name"
            labelFor="first-name"
            className="flex flex-col"
          >
            <InputGroup
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Your Last Name"
            labelFor="last-name"
            className="flex flex-col"
          >
            <InputGroup
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <div className="flex items-start space-x-2 py-4">
            <input
              type="checkbox"
              id="terms"
              className="mt-1"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <label htmlFor="terms" className="text-sm text-gray-600">
              I accept the{" "}
              <a
                href="https://www.signadot.com/terms-of-service"
                target="_tos"
                className="text-blue-600 hover:text-blue-800"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://signadot.com/privacy-policy/"
                target="privacy-policy"
                className="text-blue-600 hover:text-blue-800"
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
          <div className="pt-4">
            {!isSubmitting ? (
              <SdButton
                onClick={handleCreate}
                disabled={!formReady()}
                className="w-full"
              >
                Create Organization
              </SdButton>
            ) : (
              <div className="flex justify-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateOrgInterstitial;
