import React from "react";
import { FaGithub, FaSlack, FaYoutube } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./TopBar.module.css";
import TopBarItem from "./TopBarItem/TopBarItem";
import { useAuth } from "../../../contexts/AuthContext";
import BillingPlanBadge from "../BillingPlanBadge";
import HistoryLink from "../../HistoryLink";
import logoSolidLight from "../../../assets/images/signadot-logoform-solid-light.svg";

const TopBar = () => {
  const { user } = useAuth0();
  const { org } = useAuth();

  return (
    <ul className={`flex justify-between ${styles.container}`}>
      <div className={styles.orgSection}>
        <img src={logoSolidLight} alt="Signadot" className={styles.logo} />
        <span className={styles.orgName}>{org?.displayName || org?.name}</span>
        <HistoryLink url="/billing">
          <BillingPlanBadge />
        </HistoryLink>
      </div>

      <div className="flex">
        <li>
          <TopBarItem
            icon="headset"
            url="https://www.signadot.com/schedule-a-call"
          >
            <div className={styles.content}>Book a Demo</div>
          </TopBarItem>
        </li>
        <li className={styles.dropdown}>
          <TopBarItem icon="people" url="#">
            <div className={styles.content}>Community</div>
          </TopBarItem>
          <div className={styles.dropdownContent}>
            <TopBarItem
              icon={<FaSlack />}
              url="https://join.slack.com/t/signadotcommunity/shared_invite/zt-1estxm8pv-qfiaNfiFFCaW~eUlXsVoEQ"
            >
              <div className={styles.content}>Slack</div>
            </TopBarItem>
            <TopBarItem icon={<FaGithub />} url="https://github.com/signadot">
              <div className={styles.content}>Github</div>
            </TopBarItem>
            <TopBarItem
              icon={<FaYoutube />}
              url="https://www.youtube.com/@signadot"
            >
              <div className={styles.content}>YouTube</div>
            </TopBarItem>
          </div>
        </li>
        <li>
          <TopBarItem icon="document-open" url="https://signadot.com/docs">
            <div className={styles.content}>Docs</div>
          </TopBarItem>
        </li>
        <li className={styles.dropdown}>
          <TopBarItem url="#">
            <img src={user!.picture} alt="" className={styles.avatar} />
            <span className="mr-3" />
          </TopBarItem>
          <div className={styles.dropdownContent}>
            {user?.name || user?.email ? (
              <div className={styles.userInfo}>
                {(user?.name && user.name !== user.email && (
                  <div>{user.name}</div>
                )) ||
                  null}
                {user?.email && <div>{user.email}</div>}
              </div>
            ) : null}
            <TopBarItem icon="log-out" url="/signout">
              <div className={styles.content}>Sign Out</div>
            </TopBarItem>
          </div>
        </li>
      </div>
    </ul>
  );
};

export default TopBar;
