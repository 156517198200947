import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import logo from "../../components/Interstitial/logo";
import SdButton from "../../components/theming/SdButton";
import { useAuth } from "../../contexts/AuthContext";
import Loading from "../../components/Loading/Loading";
import AuthError from "../../components/Auth/Error";

const NewUser: React.FunctionComponent = () => {
  const { loading, error, user } = useAuth();
  const navigate = useNavigate();

  if (error) {
    return <AuthError />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex flex-col items-center max-w-md mx-auto p-8 bg-blue-50/80 backdrop-blur-sm rounded-xl shadow-md border border-blue-200">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center max-w-md mx-auto p-8 bg-blue-50/80 backdrop-blur-sm rounded-xl shadow-md border border-blue-200">
        <div className="mb-6">
          <img src={logo} alt="Signadot" className="w-16 h-16" />
        </div>

        <div className="w-full space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-gray-800">Welcome</h2>
            <p className="text-gray-600 mt-1">{user?.email ?? "new user"}</p>
          </div>

          <div className="flex justify-center">
            <SdButton
              onClick={() => navigate("/orgs/setup")}
              className="w-full"
            >
              Create a new Organization
            </SdButton>
          </div>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 text-gray-500 bg-blue-50/80">or</span>
            </div>
          </div>

          <div className="text-center">
            <h3 className="text-gray-800 font-medium mb-2">
              Already have an organization?
            </h3>
            <p className="text-gray-600 text-sm">
              Please contact the person who created the organization for an
              invite to gain access.
            </p>
          </div>

          <div className="pt-4 flex justify-center">
            <button
              type="button"
              onClick={() => navigate("/signout")}
              className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
            >
              <Icon icon="log-out" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewUser;
