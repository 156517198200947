import { Tag } from "@blueprintjs/core";
import React, { useMemo } from "react";
import moment from "moment";
import PaginatedTable from "@components/PaginatedTable/PaginatedTable";
import type { EventsApi } from "@api/AnalyticsApi";
import Loading from "@components/Loading/Loading";
import Error from "@components/Error/Error";

const columns = [
  {
    Header: "Timestamp",
    accessor: "timestamp",
  },
  {
    Header: "Event Type",
    accessor: "eventType",
  },
  {
    Header: "Cluster",
    accessor: "cluster",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Routing Key",
    accessor: "id",
  },
  {
    Header: "Labels",
    accessor: "labels",
  },
];

interface Props {
  eventsApi: EventsApi;
}

const EventsTable: React.FunctionComponent<Props> = ({ eventsApi }) => {
  const tableData = useMemo(() => {
    if (!eventsApi.data) {
      return [];
    }
    return eventsApi.data.events.map((event) => ({
      timestamp: moment(event.timestamp).format("dddd, MMM Do YYYY, h:mm:ss a"),
      eventType: event.eventType,
      cluster: event.data.clusterName,
      name: event.data.sandboxName,
      id: event.data.sandboxID,
      labels: event.data.labels
        ? Object.keys(event.data.labels).map((labelKey) => (
            <Tag className="mr-1 mb-1" key={labelKey}>
              {labelKey}: {event.data.labels?.[labelKey]}
            </Tag>
          ))
        : [],
    }));
  }, [eventsApi.data]);

  if (eventsApi.isLoading) {
    return <Loading />;
  }
  if (eventsApi.error) {
    return <Error text={eventsApi.error} />;
  }
  if (tableData.length === 0) {
    return <div>No data</div>;
  }
  return <PaginatedTable columns={columns} data={tableData} />;
};

export default EventsTable;
