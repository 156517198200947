import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { Job } from "../../@types/sd/job";
import FilteredView from "../FilteredView";
import { JOB_COLUMNS } from "./utils";
import type { FilterOptionItem } from "../FilteredView/Filter/Filter";
import SdInput from "../theming/SdInput";
import { filterJobs } from "../../pages/Testing/Jobs/Jobs/utils";

type JobsListProps = {
  jobs: Job[];
};

export const getJobOptions = (jobs: Job[]) => {
  const statusSet = new Set<string>();
  const runnersSet = new Set<string>();

  jobs.forEach((job) => {
    statusSet.add(job.status.attempts[0].phase);
    runnersSet.add(job.spec.runnerGroup);
  });

  const baseStatusOptions = [
    "succeeded",
    "failed",
    "running",
    "canceled",
    "queued",
  ];
  const statusesInJobs = [...statusSet];
  const notInJobs = baseStatusOptions.filter(
    (o) => !statusesInJobs.includes(o)
  );

  return {
    statusOptions: [
      ...statusesInJobs,
      ...notInJobs.map((s) => ({ label: s, disabled: true })),
    ] as FilterOptionItem[],
    runnerOptions: [...runnersSet] as string[],
  };
};

const JobsList: React.FC<JobsListProps> = ({ jobs }) => {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState("");
  const filteredList = useMemo(
    () => filterJobs(jobs || [], filterText),
    [jobs, filterText]
  );

  const handleRowClick = (row: Job) => {
    navigate(`/testing/jobs/${row.name}`);
  };

  // Memoize the options calculations
  const { statusOptions, runnerOptions } = useMemo(
    () => getJobOptions(jobs),
    [jobs]
  );

  return (
    <>
      <SdInput
        name="search-field"
        type="text"
        placeholder="Type to search ..."
        className="bp4-input bp4-fill bp4-medium mb-2"
        onChange={(e) => setFilterText(e.target.value)}
      />
      <FilteredView
        columns={JOB_COLUMNS}
        title={(count) => `${count} job runs`}
        rawData={filteredList}
        filters={[
          {
            label: "Status",
            filter: (job, filterValue) =>
              job.status.attempts[0]?.phase === filterValue,
            options: statusOptions,
          },
          {
            label: "Runner Group",
            filter: (job, filterValue) => job.spec.runnerGroup === filterValue,
            options: runnerOptions,
          },
        ]}
        totalWeight={5}
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default JobsList;
