import React, { useRef, useState } from "react";
import { Intent } from "@blueprintjs/core";
import classNames from "classnames";
import styles from "./Collapse.module.css";

export type CollapseTag = {
  label: string;
  intent?: Intent;
  className?: string;
};

export type CollapseOptionsFunc = {
  onToggle: VoidFunction;
  isExpanded: boolean;
};

type CollapseButtonProps = CollapseOptionsFunc & {
  className?: string;
};

const CollapseButton = ({
  onToggle,
  isExpanded,
  className,
}: CollapseButtonProps) => (
  <button onClick={onToggle} className={classNames(styles.button, className)}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={styles.icon}
      style={{
        transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
      }}
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  </button>
);

type CollapseProps = {
  title: React.ReactNode | ((opts: CollapseOptionsFunc) => React.ReactNode);
  collapsedElement?: (onExpand: () => void) => React.ReactNode;
  show: boolean;
  tags?: CollapseTag[];
  children?: React.ReactNode;
  containerStyle?: string;
  headerStyle?: string;
  bodyStyle?: string;
  defaultOpen?: boolean;
};

const Collapse = ({
  children,
  title,
  collapsedElement,
  show,
  tags,
  containerStyle,
  headerStyle,
  bodyStyle,
  defaultOpen = true,
}: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(children ? defaultOpen : false);
  const contentRef = useRef<HTMLDivElement>(null);

  const displayCollapsed = !isOpen && collapsedElement;
  const titleOverrideCollapseButton = typeof title === "function";

  const toggleCollapsible = () => {
    if (displayCollapsed || !children) return;

    setIsOpen(!isOpen);
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [containerStyle!]: !!containerStyle,
      })}
    >
      <div
        className={classNames(styles.header, {
          [headerStyle!]: !!headerStyle,
          [styles.header_no_pointer]: displayCollapsed,
        })}
        onClick={toggleCollapsible}
      >
        <h4 className={styles.title}>
          {titleOverrideCollapseButton
            ? title({ onToggle: toggleCollapsible, isExpanded: isOpen })
            : title}
        </h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          {tags &&
            tags.map((tag) => (
              <span
                className={classNames(styles.tag, tag.className ?? "", {
                  [styles.tag_danger]: tag.intent === Intent.DANGER,
                  [styles.tag_success]:
                    tag.intent === Intent.SUCCESS ||
                    tag.intent === Intent.PRIMARY,
                  [styles.tag_none]: tag.intent === Intent.NONE,
                  [styles.tag_warning]: tag.intent === Intent.WARNING,
                })}
                key={tag.label}
              >
                {tag.label}
              </span>
            ))}

          <CollapseButton
            onToggle={toggleCollapsible}
            isExpanded={isOpen}
            className={
              !children || !displayCollapsed || titleOverrideCollapseButton
                ? styles.hide_collapse
                : undefined
            }
          />
        </div>
      </div>
      {displayCollapsed && collapsedElement?.(() => setIsOpen(true))}
      <div
        ref={contentRef}
        className={classNames(styles.collapsible, {
          [bodyStyle!]: isOpen && !!bodyStyle,
        })}
        style={{
          maxHeight: isOpen ? "fit-content" : "0",
        }}
      >
        {children}
      </div>
    </div>
  );
};

Collapse.CollapseButton = CollapseButton;

export default Collapse;
