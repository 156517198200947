import React from "react";
import logo from "./logo";

interface Props {
  includeHeaderWithLogo?: boolean;
  children: React.ReactNode;
}

const HeaderWithLogo = function () {
  return (
    <div className="flex mb-6">
      <img src={logo} alt="Signadot" className="w-16 h-16" />
    </div>
  );
};

const Interstitial: React.FunctionComponent<Props> = function ({
  children,
  includeHeaderWithLogo = true,
}) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center max-w-md mx-auto p-8 bg-blue-50/80 backdrop-blur-sm rounded-xl shadow-md border border-blue-200">
        {includeHeaderWithLogo && <HeaderWithLogo />}
        {children}
      </div>
    </div>
  );
};

export default Interstitial;
