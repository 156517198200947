import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MultiTabsLayout from "../../../../components/MultiTabsLayout/MultiTabsLayout";
import {
  TestDetailClickSpecificationTab,
  TestDetailClickTriggersTab,
} from "../../../../Constants";
import type { Test } from "../../../../@types/sd/testspec";
import { useGetTest } from "../../../../api/TestsApi";
import Error from "../../../../components/Error/Error";
import Loading from "../../../../components/Loading/Loading";
import DeleteTestSpecAlert from "./tabs/DeleteTestSpecAlert";
import useDisclosure from "../../../../hooks/UseDisclosure";
import type { TabProps } from "../../../../components/theming/Tabs/Tabs";
import Triggers from "./tabs/Triggers";
import styles from "./TestDetail.module.css";
import Badge from "../../../../components/theming/Badge";
import { getStatus } from "./util";
import SyntheticTestEditor from "../../Synthetics/SyntheticTestEditor/SyntheticTestEditor";

const TestDetail: React.FC = () => {
  const navigate = useNavigate();

  const [test, setTest] = useState<Test>({
    createdAt: "",
    name: undefined,
    spec: {
      script:
        "# You can edit this content.\n" +
        "# Refer to https://signadot.com/docs/reference/smart-tests for help.\n\n" +
        "res = http.get(\n" +
        '    url="https://httpbin.org/get", # can also be cluster internal URLs like http://name.namespace.svc\n' +
        '    headers={"h1": "k1"},\n' +
        "    capture=True, # enables SmartDiff\n" +
        '    name="getAPI"\n' +
        ")\n\n" +
        "print(res.status_code)\n" +
        "print(res.body())\n",
    },
    updatedAt: "",
  } as unknown as Test);
  const deleteModal = useDisclosure(false);

  const { testName: testNameParam } = useParams<{ testName: string }>();

  const isExistingTest = testNameParam !== "editor";

  const { isLoading, error, data } = useGetTest(testNameParam!, {
    enabled: isExistingTest,
  });

  useEffect(() => {
    if (!data) return;
    setTest(data);
  }, [data]);

  if (isLoading) {
    return (
      <div className={styles.loadingScreen}>
        <Loading />
      </div>
    );
  }

  if (error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      navigate("/testing/tests");
      return null;
    }
    return (
      <Error
        text={`Error: ${
          error.message || "Unknown error fetching the Smart Test"
        }`}
      />
    );
  }

  const tabs: TabProps[] = [
    {
      label: "Specification",
      slug: "specification",
      content: (
        <SyntheticTestEditor test={test} isExistingTest={isExistingTest} />
      ),
      eventName: TestDetailClickSpecificationTab,
    },
    {
      label: (
        <div className={styles.triggerBadgeContainer}>
          <div>Triggers</div>
          <Badge label={test.spec?.triggers?.length.toString() ?? "0"} />
        </div>
      ),
      slug: "triggers",
      content: <Triggers test={test} />,
      eventName: TestDetailClickTriggersTab,
      hide: !isExistingTest,
    },
  ];

  return (
    <MultiTabsLayout
      status={getStatus(test, !isExistingTest, navigate)}
      tabs={tabs}
      title={test?.name ?? "New Hosted Test"}
      breadcrumbs={[
        {
          text: "Hosted Tests",
          onClick: () => navigate("/testing/hosted-tests"),
        },
        { text: test.name ?? "Editor" },
      ]}
      isLoading={isLoading}
      actions={[
        {
          label: "Delete",
          onClick: deleteModal.open,
          display: isExistingTest,
          isRiskAction: true,
          icon: "trash",
        },
      ]}
    >
      <DeleteTestSpecAlert
        isDeleteModalOpen={deleteModal.isOpen}
        name={test?.name ?? ""}
        onCancel={deleteModal.close}
      />
    </MultiTabsLayout>
  );
};

export default TestDetail;
