import { Intent } from "@blueprintjs/core";
import type { Test } from "../../../../@types/sd/testspec";

interface ValidationError {
  message: string;
  intent: Intent;
}

export const validateTest = (test: Test) => {
  const errors: ValidationError[] = [];

  if (!test.name) {
    errors.push({
      message: "Test name is required",
      intent: Intent.DANGER,
    });
  }

  if (!test.spec?.script) {
    errors.push({
      message: "Test script is required",
      intent: Intent.DANGER,
    });
  }

  return {
    getFirstError: () => errors[0],
    hasErrors: () => errors.length > 0,
  };
}; 