import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import {
  FILTER_TEST_EXECUTIONS_API_NAME,
  useGetSandboxTestExecutions,
} from "../../../../api/TestExecutionsApi";
import style from "./Tests.module.css";
import { groupByTestCompletion } from "./utils";
import EmptyExecutions from "./EmptyExecutions/EmptyExecutions";
import SummaryExecutions from "../../../Testing/TestExecutionDetail/common/SummaryExecutions/SummaryExecutions";
import {
  IncompleteTestDetails,
  TestExecutionCheck,
  TestExecutionDiff,
} from "../../../Testing/TestExecutionDetail/TestExecutionWrapper";
import TestResultCard from "./TestResultCard/TestResultCard";

interface Props {
  sandbox: SandboxV2;
}

const Tests: React.FC<Props> = ({ sandbox }) => {
  const queryClient = useQueryClient();
  const { hash } = useLocation();

  useEffect(() => {
    queryClient.invalidateQueries(FILTER_TEST_EXECUTIONS_API_NAME);
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState<
    "diffs" | "checks" | "tests" | "none"
  >("none");

  const { isSuccess, isLoading, data } = useGetSandboxTestExecutions(
    sandbox.name,
    {
      enabled: !!sandbox?.name,
    }
  );

  useEffect(() => {
    if (hash) {
      const parsedHash = hash.substring(1);
      if (["diffs", "checks", "tests"].includes(parsedHash)) {
        setHighlightedSection(parsedHash as typeof highlightedSection);
      }
    }
  }, [hash]);

  useEffect(() => {
    if (!data) return;

    if (highlightedSection === "none") return;

    const element = containerRef.current?.querySelector(
      `[data-section="${highlightedSection}"]`
    );

    if (!element) return;

    const timeoutID = setTimeout(() => setHighlightedSection("none"), 3000);
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "center",
    });

    return () => {
      clearTimeout(timeoutID);
    };
  }, [data]);

  if (!isSuccess || isLoading) {
    return null;
  }

  if (!data || data.length === 0) {
    return <EmptyExecutions />;
  }

  const groupedExecutions = groupByTestCompletion(data);

  return (
    <div ref={containerRef}>
      <SummaryExecutions executions={data} />
      <div className={style.resultsSection}>
        <div className={style.resultsHeader}>
          <h2 className={style.resultsTitle}>Results</h2>
        </div>
        <div className={style.testList}>
          {groupedExecutions.completed.map((execution) => (
            <TestResultCard key={execution.name} execution={execution}>
              <div className={style.testComponentsContainer}>
                <TestExecutionCheck
                  testName={execution.spec.test}
                  textExecutionName={execution.name}
                />
                <TestExecutionDiff
                  testName={execution.spec.test}
                  textExecutionName={execution.name}
                />
              </div>
            </TestResultCard>
          ))}
        </div>

        {groupedExecutions.others.length > 0 && (
          <div className={style.testList}>
            {groupedExecutions.others.map((execution) => (
              <TestResultCard key={execution.name} execution={execution}>
                <div className={style.testComponentsContainer}>
                  <IncompleteTestDetails
                    execution={execution}
                    key={execution.name}
                  />
                </div>
              </TestResultCard>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tests;
