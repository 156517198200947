import React, { createContext, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import type { Test } from "../../../../@types/sd/testspec";
import { useSaveTest } from "../../../../api/TestsApi";
import { validateTest } from "./validate";
import useDisclosure from "../../../../hooks/UseDisclosure";
import {
  useCreateTestExecution,
  useGetTestExecution,
} from "../../../../api/TestExecutionsApi";
import { useGetJob } from "../../../../api/JobsApi";
import useGetJobLogs from "../../../../hooks/UseGetJobLogs";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AttemptPhaseEnum } from "../../../../@types/sd/job.d.ts";
import useLocalSavedPreferences, {
  PREFERENCES_NAMES,
} from "../../../../hooks/UseLocalSavedPreferences";
import { getTimeAgo } from "../../../../util/Util";

const toaster = OverlayToaster.create();

type SyntheticTestEditorContextType = {
  test: Test;
  setTest: React.Dispatch<React.SetStateAction<Test>>;
  isExistingTest: boolean;
  clusterNameTest: string | undefined;
  setClusterNameTest: (name: string | undefined) => void;
  logsMessage: string;
  setLogsMessage: (message: string) => void;
  showLogPanelContent: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
  showSetName: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
  onSave: () => void;
  handleSaveAndRunTest: () => void;
  handleRunTest: () => void;
};

const SyntheticTestEditorContext =
  createContext<SyntheticTestEditorContextType | null>(null);

export const useSyntheticTestEditor = () => {
  const context = useContext(SyntheticTestEditorContext);
  if (!context) {
    throw new Error(
      "useSyntheticTestEditor must be used within a SyntheticTestEditorProvider"
    );
  }
  return context;
};

type SyntheticTestEditorProviderProps = {
  children: React.ReactNode;
  test: Test;
  isExistingTest: boolean;
};

export const SyntheticTestEditorProvider: React.FC<
  SyntheticTestEditorProviderProps
> = ({ children, test: originalTest, isExistingTest }) => {
  const [test, setTest] = useState(originalTest);
  const { setPreference, values: savedPreferences } =
    useLocalSavedPreferences();
  const [clusterNameTest, setClusterNameTest] = useState<string | undefined>();
  const [executionName, setExecutionName] = useState<string>();
  const [targetJobName, setTargetJobName] = useState<string>();
  const [ranAt, setRanAt] = useState<string>();
  const [logsMessage, setLogsMessage] = useState<string>(
    "No Hosted Test started"
  );
  const isUserCallingTestRef = useRef(false);
  const navigate = useNavigate();

  const showLogPanelContent = useDisclosure(false);
  const showSetName = useDisclosure(false);

  const [originTest, setOriginTest] = useState<"save" | "save_run">();

  const testExecutionApi = useGetTestExecution(
    test?.name ?? "",
    executionName!,
    {
      enabled: test?.name !== undefined && executionName !== undefined,
    }
  );

  const jobDetailApi = useGetJob(targetJobName!, {
    enabled: targetJobName !== undefined,
  });

  const { mergedLogs: logs } = useGetJobLogs({
    job: jobDetailApi.data,
  });

  const saveTestSpecApi = useSaveTest(
    () => {
      toaster.show({
        message: isExistingTest
          ? "Hosted Test updated"
          : "Hosted Test created",
        intent: Intent.SUCCESS,
      });

      if (!isExistingTest) {
        const tab = originTest === "save" ? "triggers" : "specification";
        navigate(`/testing/hosted-tests/${test?.name}/${tab}`, {
          replace: true,
        });
      }
    },
    (apiError) => {
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  const createTestExecutionApi = useCreateTestExecution(
    (response) => {
      toaster.show({
        message: "Synthetic Test job created",
        intent: Intent.SUCCESS,
      });
      setExecutionName(response.name);

      setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, (prev) => ({
        ...prev,
        testName: test.name,
        executionName: response.name,
        ranAt: new Date(),
      }));

      setLogsMessage(
        "Hosted Test started. Wait for Hosted Test job is created..."
      );
      showLogPanelContent.open();
    },
    (apiError) => {
      setLogsMessage("Hosted Test no started. An error occurred");
      toaster.show({
        message: apiError.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  const checkTestNameMissing = () => {
    if (isExistingTest || originTest) {
      return false;
    }

    showSetName.open();
    return true;
  };

  const onSave = () => {
    if (checkTestNameMissing()) {
      setOriginTest("save");
      return;
    }

    const validationResult = validateTest(test ?? {});

    const firstError = validationResult.getFirstError();
    if (firstError) {
      toaster.show(firstError);
      return;
    }

    saveTestSpecApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test.name}`,
      data: test?.spec,
    });
  };

  const handleRunTest = () => {
    jobDetailApi.remove();
    testExecutionApi.remove();
    createTestExecutionApi.reset();
    setExecutionName(undefined);
    setTargetJobName(undefined);
    setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, {});

    setLogsMessage("Starting test");
    createTestExecutionApi.mutate({
      url: `/api/v2/orgs/:orgName/tests/${test.name}/executions`,
      data: {
        executionContext: {
          cluster: clusterNameTest!,
        },
      },
    });
    isUserCallingTestRef.current = true;
  };

  const handleSaveAndRunTest = () => {
    if (checkTestNameMissing()) {
      setOriginTest("save_run");
      return;
    }

    saveTestSpecApi.mutate(
      {
        url: `/api/v2/orgs/:orgName/tests/${test.name}`,
        data: test?.spec,
      },
      {
        onSuccess: handleRunTest,
      }
    );
  };

  React.useEffect(() => {
    if (
      !targetJobName &&
      test.name === savedPreferences["smart-tests/debug"].testName
    ) {
      setTargetJobName(savedPreferences["smart-tests/debug"].targetJobName);
      setRanAt(
        getTimeAgo(savedPreferences["smart-tests/debug"].ranAt?.toString())
      );
      showLogPanelContent.open();
    }
  }, [savedPreferences["smart-tests/debug"]]);

  React.useEffect(() => {
    if (!testExecutionApi.isSuccess) return;

    const status = testExecutionApi.data.status;
    setLogsMessage(`Synthetic Test execution in ${status.phase} state`);

    if (status.phase === "pending") return;

    if (status.phase === "failed" && status.finalState?.failed?.message) {
      setLogsMessage(
        `Synthetic Test execution failed with ${status.finalState?.failed?.message}`
      );
      return;
    }

    setLogsMessage(`Synthetic Test execution running with ${status.targetJob}`);
    setTargetJobName(status.targetJob);

    setPreference(PREFERENCES_NAMES.DEBUG_SMART_TEST, (prev) => ({
      ...prev,
      targetJobName: status.targetJob,
    }));
    showLogPanelContent.open();
  }, [testExecutionApi.data]);

  React.useEffect(() => {
    if (!jobDetailApi.isSuccess || jobDetailApi.isRefetching) return;
    if (
      jobDetailApi.data.status.attempts[0].phase === AttemptPhaseEnum.QUEUED
    ) {
      setLogsMessage(`Waiting job ${targetJobName} to start`);
      return;
    }

    let runText = "";
    if (ranAt && !isUserCallingTestRef.current) {
      runText = ` that ran ${ranAt}`;
    }

    const header = `Showing logs for job ${targetJobName}${runText}\n`;
    setLogsMessage(header + logs.map((l) => l.message).join(""));

    const attempt = jobDetailApi.data.status.attempts[0];

    if (attempt.phase === "failed") {
      setLogsMessage((prev) => {
        prev += `Run failed with ${attempt.state.failed.message}`;
        return prev;
      });
    }

    if (attempt.phase === "succeeded") {
      setLogsMessage((prev) => {
        prev += "Run finished successfully";
        return prev;
      });
    }
  }, [jobDetailApi, logs, targetJobName]);

  React.useEffect(() => {
    if (isExistingTest) return;
    if (!originTest) return;

    switch (originTest) {
      case "save":
        onSave();
        break;
      case "save_run":
        handleSaveAndRunTest();
        break;
    }

    setOriginTest(undefined);
  }, [test]);

  const value = {
    test,
    setTest,
    isExistingTest,
    clusterNameTest,
    setClusterNameTest,
    logsMessage,
    setLogsMessage,
    showLogPanelContent,
    showSetName,
    onSave,
    handleSaveAndRunTest,
    handleRunTest,
  };

  return (
    <SyntheticTestEditorContext.Provider value={value}>
      {children}
    </SyntheticTestEditorContext.Provider>
  );
};
