import type { TestExecution } from "@constants/@types/sd/testexecutions";
import React from "react";
import { Icon } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import styles from "./ExecutionRow.module.css";
import CapturesInfo from "../../TestSpecs/ListExecutions/table/CapturesInfo";
import classNames from "classnames";

type ExecutionRowProps = {
  execution: TestExecution;
  onSelect: (execution: TestExecution) => void;
};

export function getDuration(startedAt: string, finishedAt: string): string {
  const start = new Date(startedAt);
  const end = new Date(finishedAt);

  // Calculate the difference in milliseconds
  const durationMs = end.getTime() - start.getTime();

  // Convert milliseconds to hours, minutes, and seconds
  const hours = Math.floor(durationMs / (1000 * 60 * 60));
  const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);

  let formattedTime = "";
  if (hours > 0) formattedTime += `${hours}h `;
  if (minutes > 0) formattedTime += `${minutes}m `;
  if (hours === 0 && seconds > 0) formattedTime += `${seconds}s`;

  return formattedTime.trim();
}

const getEnvironment = (execution: TestExecution) => {
  const environment = execution.spec.executionContext.routing?.sandbox;
  if (environment) {
    return { type: "sandbox", value: environment };
  }

  if (execution.spec.executionContext.routing?.routegroup) {
    return {
      type: "routeGroup",
      value: execution.spec.executionContext.routing?.routegroup,
    };
  }

  return { type: "baseline", value: "baseline" };
};

const getExecID = (testName: string, executionID: string): string =>
  executionID.split(testName)[1];

const ExecutionRow = ({ execution, onSelect }: ExecutionRowProps) => {
  const environment = getEnvironment(execution);
  const execID = getExecID(execution.computedTestName, execution.name);
  const isHosted = execution.spec.embeddedSpec === undefined;

  return (
    <div className={styles.executionRow}>
      <div className={styles.rowLeft}>
        <div className={styles.heading}>
          <div className={styles.title} onClick={() => onSelect(execution)}>
            <Popover2>
              <Tooltip2
                content={!isHosted ? "External source" : "Hosted source"}
                intent="none"
              >
                <Icon
                  icon={!isHosted ? "git-branch" : "cloud"}
                  size={12}
                  className={styles.sourceIcon}
                />
              </Tooltip2>
            </Popover2>
          </div>
          <Popover2>
            <Tooltip2 content="View more details" intent="none">
              <div
                className={classNames({
                  [styles.openDetailsIcon]: isHosted,
                })}
              >
                {execID ? (
                  <span>
                    <span>{execution.spec.test}</span>
                    <strong>{execID}</strong>
                  </span>
                ) : (
                  <strong>{execution.name}</strong>
                )}
                {isHosted && <Icon icon="share" />}
              </div>
            </Tooltip2>
          </Popover2>
        </div>

        <div>
          <div className={styles.metadata}>
            <div className={styles.status}>
              {execution.spec?.embeddedSpec?.runID && (
                <span>Run ID: {execution.spec?.embeddedSpec?.runID}</span>
              )}
              <span>
                {execution.status.startedAt && execution.status.finishedAt
                  ? `Duration: ${getDuration(
                      execution.status.startedAt,
                      execution.status.finishedAt,
                    )}`
                  : ""}
              </span>
            </div>
          </div>
          <div className={styles.metadata}>
            {environment.type && (
              <span className={styles.label}>
                <span>environment=</span>
                <Popover2>
                  <Tooltip2
                    content={
                      <div>
                        <div>Environment type: {environment.type}</div>
                        <div>Environment value: {environment.value}</div>
                        <div>
                          Revision:{" "}
                          {execution.spec.executionContext.routing?.revision}
                        </div>
                      </div>
                    }
                    intent="none"
                  >
                    <strong>
                      {environment.type !== "baseline"
                        ? `${environment.type}/`
                        : ""}
                      {environment.value}
                      {execution.spec.executionContext.routing?.revision !==
                      undefined
                        ? `@r${execution.spec.executionContext.routing?.revision}`
                        : ""}
                    </strong>
                  </Tooltip2>
                </Popover2>
              </span>
            )}
            {execution.spec.executionContext.routing?.routingKey && (
              <span className={styles.label}>
                <span>routingKey=</span>
                <strong>
                  {execution.spec.executionContext.routing?.routingKey}
                </strong>
              </span>
            )}
            <span className={styles.label}>
              <span>published=</span>
              <strong>
                {execution.spec.executionContext.publish ? "true" : "false"}
              </strong>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.rowRight}>
        <CapturesInfo execution={execution} />
      </div>
    </div>
  );
};

export default ExecutionRow;
