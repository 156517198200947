/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useMemo, useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";
import { SiKubernetes } from "react-icons/si";
import { IconNames } from "@blueprintjs/icons";
import useApi from "@hooks/UseApi";
import useApiMutation from "@hooks/UseApiMutation";
import type { FormattedSandboxV2 } from "@utils/Util";
import { formatSandboxesV2 } from "@utils/Util";
import Loading from "@components/Loading/Loading";
import Spacer from "@components/Util/Util";
import PaginatedTable from "@components/PaginatedTable/PaginatedTable";
import CreateFirst from "@components/CreateFirst";
import {
  SANDBOX_STATUSES_API_NAME,
  SANDBOXES_API_NAME,
  useSandboxesApi,
} from "@api/SandboxesApi";
import SdButton from "@components/theming/SdButton";
import { SdHeading1 } from "@components/theming/SdHeading";
import FilterTag from "@components/FilterTag";
import {
  SandboxDetailOpenSpecInEditor,
  SandboxesGotoCreateSandboxPage,
} from "@constants/Constants";
import type { SandboxV2 } from "@constants/@types/sd/sandboxv2";
import type { FilterInputOption } from "@components/FilterInput";
import DeleteSandboxAlert from "./components/DeleteSandboxAlert";
import type { SandboxStatus } from "../../@types/sd/sandbox";

const toaster = OverlayToaster.create();

interface SandboxStatusesApiResponse {
  statuses: SandboxStatus[];
}

const columns = [
  {
    Header: "Labels",
    accessor: "labels",
    search: true,
    show: false,
  },
  {
    Header: "Name",
    accessor: "name",
    search: true,
  },
  {
    Header: "Description",
    accessor: "description",
    search: true,
  },
  {
    Header: "Cluster",
    accessor: "clusterName",
    search: true,
  },
  {
    Header: "Created",
    accessor: "createdAt",
  },
  {
    Header: "Smart Tests",
    accessor: "testStatus",
  },
  {
    Header: "Action",
    accessor: "icon",
  },
];

const identifiersToInvalidate = [SANDBOXES_API_NAME, SANDBOX_STATUSES_API_NAME];

const Sandboxes = function () {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const showUnhealthy = !!search.get("showUnhealthy");
  const navigate = useNavigate();
  const { sandboxesList, error } = useSandboxesApi();
  const redirectingToTestsRef = useRef(false);

  const deletion = useApiMutation(
    "delete_sandbox",
    "DELETE",
    identifiersToInvalidate /* invalidation of get query output */
  );

  const clusters = useApi("clusters", `/api/v1/orgs/:orgName/clusters`);
  const status = useApi<SandboxStatusesApiResponse>(
    SANDBOX_STATUSES_API_NAME,
    `/api/v1/orgs/:orgName/sandboxes/status`,
    {
      refetchInterval: 30000,
    }
  );

  const sandboxes = useMemo(() => {
    if (!status.data || !sandboxesList) {
      return [];
    }
    let toFormat = sandboxesList;
    let toFormatStatuses = status.data.statuses;
    if (showUnhealthy) {
      toFormat = [];
      toFormatStatuses = [];
      for (let i = 0; i < sandboxesList.length; i++) {
        if (!status.data?.statuses[i].ready) {
          toFormat.push(sandboxesList[i]);
          toFormatStatuses.push(status.data?.statuses[i]);
        }
      }
    }
    return formatSandboxesV2(
      toFormat,
      (sandboxName: string) => {
        navigate(`/sandbox/name/${sandboxName}/tests`);
        redirectingToTestsRef.current = true;
      },
      toFormatStatuses
    );
  }, [sandboxesList, status]);

  // state for the modal for confirming deletion.
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedSandbox, setSelectedSandbox] =
    useState<FormattedSandboxV2 | null>(null);

  const handleDelete = (row: FormattedSandboxV2) => {
    setSelectedSandbox(row);
    setDeleteModalOpen(true);
  };
  const handleRowClick = (row: FormattedSandboxV2) => {
    if (redirectingToTestsRef.current) {
      return;
    }

    if (!row.name.text) {
      navigate(`/sandbox/id/${row.id}`);
    } else {
      navigate(`/sandbox/name/${row.name.text}`);
    }
  };

  if ((!sandboxesList && !error) || clusters.isLoading || clusters.isIdle) {
    return <Loading />;
  }
  if (error) {
    return <>Error: {error}</>;
  }
  if (!clusters.isSuccess) {
    return <>There was an error fetching cluster data.</>;
  }

  // If no cluster is present, display the UX to create the first cluster
  const clusterList = _.get(clusters, ["data", "clusters"]) || [];
  if (clusterList.length === 0) {
    return (
      <div style={{ marginTop: "5em" }}>
        <h1>Sandboxes</h1>
        <br />
        <CreateFirst
          icon={<SiKubernetes size="2.4em" />}
          message="No cluster available to create sandboxes"
          buttonLabel="Connect Cluster"
          onClick={() => {
            navigate("/settings/clusters#connect");
          }}
        />
      </div>
    );
  }

  const gotoCreateSandboxPage = () => {
    navigate("/sandboxes/create");
  };

  if (deletion.error) {
    toaster.show({
      message: _.get(deletion.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deletion.reset();
  } else if (deletion.isSuccess) {
    toaster.show({ message: "Sandbox Deleted", intent: Intent.SUCCESS });
    deletion.reset();
  }

  const clearSelection = () => {
    setSelectedSandbox(null);
    setDeleteModalOpen(false);
  };

  return (
    <>
      <SdHeading1 small lightBackground>
        Sandboxes
      </SdHeading1>
      <br />
      {showUnhealthy && (
        <>
          <FilterTag text="status: error" />
          <br />
        </>
      )}
      {(Array.isArray(sandboxesList) && sandboxesList.length > 0 && (
        <>
          <PaginatedTable<FormattedSandboxV2>
            columns={columns}
            data={sandboxes}
            onRowClickCallback={handleRowClick}
            onActionCallback={handleDelete}
            enableSearch
          />
          <Spacer />
          <SdButton
            onClick={gotoCreateSandboxPage}
            eventName={SandboxesGotoCreateSandboxPage}
            icon="plus"
          >
            Create Sandbox
          </SdButton>
        </>
      )) || (
        <CreateFirst
          icon={IconNames.DOCUMENT_OPEN}
          message="Create your first sandbox"
          buttonLabel="Create Sandbox"
          onClick={gotoCreateSandboxPage}
        />
      )}
      {/* Modal Dialog to confirm deletion */}
      {selectedSandbox && (
        <DeleteSandboxAlert
          name={selectedSandbox!.name.text!}
          isDeleteModalOpen={isDeleteModalOpen}
          onCancel={clearSelection}
          onComplete={clearSelection}
        />
      )}
      {/* Top level controls */}
    </>
  );
};

export default Sandboxes;
