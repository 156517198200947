import React, { useState, useRef, useEffect } from "react";
import Markdown from "react-markdown";

import styles from "./Chat.module.css";
import { useChat } from "./context/ChatContext";
import { getTimeAgo } from "../../util/Util";

type ChatConsumerProps = {
  onToggle?: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const ChatConsumer = ({ onToggle }: ChatConsumerProps = {}) => {
  const { addMessageToThread, messages, state } = useChat();

  const [input, setInput] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(messages);

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {
    if (!input.trim()) return;
    addMessageToThread(input);
    setInput("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.headerContent}>
          <div className={styles.titleRow}>
            <div className={styles.cardTitle}>Signadot Assistant</div>
            <button
              type="button"
              className={styles.closeButton}
              onClick={onToggle}
              aria-label="Close chat"
            >
              ✕
            </button>
          </div>
          <div className={styles.cardSubtitle}>(Dogfood)</div>
        </div>
      </div>
      <div className={styles.chatContent}>
        {messages.length === 0 ? (
          <div className={styles.emptyState}>
            Send a message to start chatting
          </div>
        ) : (
          messages.map((m) => (
            <div
              key={m.id}
              className={`${styles.messageWrapper} ${
                m.role === "user"
                  ? styles.messageWrapperUser
                  : styles.messageWrapperAssistant
              }`}
            >
              <div className={styles.messageContainer}>
                <div className={styles.messageSender}>
                  {m.role === "user" ? "You" : "Assistant"}
                </div>
                <div
                  className={`${styles.messageContent} ${
                    m.role === "user"
                      ? styles.messageContentUser
                      : styles.messageContentAssistant
                  }`}
                >
                  <Markdown>{m.content}</Markdown>
                </div>
                <div className={styles.messageTime}>
                  {getTimeAgo(m.createdAt)}
                </div>
              </div>
            </div>
          ))
        )}
        {state === "creating_thread" && (
          <div
            className={`${styles.messageWrapper} ${styles.messageWrapperAssistant}`}
          >
            <div className={styles.messageContainer}>
              <div className={styles.messageSender}>System</div>
              <div
                className={`${styles.messageContent} ${styles.messageContentAssistant}`}
              >
                <div className={styles.typingIndicator}>
                  Initializing chat...
                  <div className={styles.typingDot} />
                  <div className={styles.typingDot} />
                  <div className={styles.typingDot} />
                </div>
              </div>
            </div>
          </div>
        )}
        {state === "waiting_response" && (
          <div
            className={`${styles.messageWrapper} ${styles.messageWrapperAssistant}`}
          >
            <div className={styles.messageContainer}>
              <div className={styles.messageSender}>Assistant</div>
              <div
                className={`${styles.messageContent} ${styles.messageContentAssistant}`}
              >
                <div className={styles.typingIndicator}>
                  <div className={styles.typingDot} />
                  <div className={styles.typingDot} />
                  <div className={styles.typingDot} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.cardFooter}>
        <form className={styles.inputForm}>
          <textarea
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Type your message (Shift+Enter for new line)..."
            className={styles.input}
            disabled={state !== "idle"}
          />
          <button
            type="button"
            className={styles.button}
            onClick={handleSubmit}
            disabled={state !== "idle" || !input.trim()}
          >
            <svg
              className={styles.sendIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="22" y1="2" x2="11" y2="13" />
              <polygon points="22 2 15 22 11 13 2 9 22 2" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};
