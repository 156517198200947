import type React from "react";
import { useEffect, useMemo, useState } from "react";
import type { UseQueryOptions, UseQueryResult } from "react-query";
import type { ControlProps } from "./common/Controls/Controls";
import { ViewType } from "./common/Controls/Controls";
import { useGetFindings } from "../../../api/FindingsApi";
import type { Findings, TrafficDiffFinding } from "../../../@types/sd/findings";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../@types/sd/findings.d.ts";
import { filterFindings } from "./util";
import { useGetTestExecution } from "../../../api/TestExecutionsApi";
import type { ApiError } from "../../../hooks/UseApi";
import type {
  TestExecution,
  TrafficDiff,
} from "../../../@types/sd/testexecutions";
import { getCountOfSummary } from "./common/utils";

type useGetTestExecutionDetailsProps = {
  testName?: string;
  executionName?: string;
};

type useGetTestExecutionDetailsReturn = {
  raw: {
    executionDetail: UseQueryResult<TestExecution, ApiError>;
    findings: UseQueryResult<Findings, ApiError>;
  };
  tests: {
    diff: {
      controlProps: ControlProps;
      setControlProps: React.Dispatch<React.SetStateAction<ControlProps>>;
      filteredFindings: TrafficDiffFinding[];
    };
  };
};

export type MajorFindingCountLabel = "high" | "medium" | "low" | "none";

export const getFindingsCount = (trafficDiff: TrafficDiff) => {
  const { red, yellow, green } = trafficDiff;

  const redCount = getCountOfSummary(red);
  const yellowCount = getCountOfSummary(yellow);
  const greenCount = getCountOfSummary(green);

  let major;

  if (redCount > 0) {
    major = {
      count: redCount,
      label: "high",
    };
  } else if (yellowCount > 0) {
    major = {
      count: yellowCount,
      label: "medium",
    };
  } else if (greenCount > 0) {
    major = {
      count: greenCount,
      label: "low",
    };
  }

  return {
    count: {
      red: redCount,
      yellow: yellowCount,
      green: greenCount,
    },
    major: major
      ? {
          ...major,
          label: major.label as MajorFindingCountLabel,
        }
      : undefined,
  };
};

const useGetTestExecutionDetails = ({
  testName,
  executionName,
}: useGetTestExecutionDetailsProps): useGetTestExecutionDetailsReturn => {
  const [controlProps, setControlProps] = useState<ControlProps>({
    showHeaderFindings: true,
    showMetadataFindings: true,
    viewType: ViewType.Grouped,
    filterByCategories: [],
    findingsCount: {
      high: 0,
      low: 0,
      medium: 0,
    },
  });

  const executionDetail = useGetTestExecution(testName!, executionName!);
  const findingsCall = useGetFindings(
    testName!,
    executionName!,
    0,
    undefined,
    undefined,
    {
      enabled:
        executionDetail.isSuccess &&
        executionDetail.data.status.phase === "succeeded",
    } as UseQueryOptions
  );
  const findings: TrafficDiffFinding[] = useMemo(() => {
    if (!findingsCall.data || !Array.isArray(findingsCall.data.findings))
      return [] as TrafficDiffFinding[];
    return findingsCall.data.findings.sort((a, b) => b.relevance - a.relevance);
  }, [findingsCall.data, findingsCall.status]);

  const filteredFindings = useMemo(
    () => filterFindings(findings, controlProps),
    [findings, controlProps]
  );

  useEffect(() => {
    if (!executionDetail.data) return;

    const trafficDiff = executionDetail.data.results?.trafficDiff;
    if (!trafficDiff) return;

    const { major, count } = getFindingsCount(trafficDiff);

    let initialSelected: DiffCat | undefined;
    switch (major?.label || "") {
      case "high":
        initialSelected = DiffCat.Red;
        break;
      case "medium":
        initialSelected = DiffCat.Yellow;
        break;
      case "low":
        initialSelected = DiffCat.Green;
        break;
      default:
        initialSelected = undefined;
    }

    setControlProps((prev) => ({
      ...prev,
      filterByCategories: initialSelected ? [initialSelected] : [],
      findingsCount: {
        high: count.red,
        medium: count.yellow,
        low: count.green,
      },
    }));
  }, [executionDetail.data]);

  return {
    raw: {
      executionDetail,
      findings: findingsCall,
    },
    tests: {
      diff: {
        controlProps,
        setControlProps,
        filteredFindings,
      },
    },
  };
};

export default useGetTestExecutionDetails;
