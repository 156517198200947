/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions  */
import React, { useEffect } from "react";
import classNames from "classnames";
import style from "./FilteredView.module.css";
import type { FilterOptions } from "./Filter";
import Filters from "./Filters";
import usePagination from "../../hooks/UsePagination";
import ButtonGroup from "../PaginatedTable/ButtonGroup";
import { useFilteredViewContext } from "./FilteredViewContext";

export type FilteredViewColumn<T> = {
  id: string;
  render: (value: T) => React.ReactNode;

  // Number of spaces to be used
  weight: number;
};

export type ColumnHeader =
  | {
      label: string;
      className?: string;
    }
  | string;

export type FilteredViewProps<T> = {
  title: React.ReactNode | ((filteredCount: number) => React.ReactNode);
  columns: FilteredViewColumn<T>[];

  /**
   * `columnsHeaders` is optional, but if provided,
   * it MUST match the length of `columns`.
   */
  columnsHeaders?: ColumnHeader[];
  filters?: FilterOptions<T>[];

  // This means the total of columns created by the grid
  totalWeight: number;

  // Pagination
  pageSize?: number;

  // Callbacks
  onRowClick?: (row: T) => void;

  rowClassName?: string;
  emptyRowsRender?: () => React.ReactNode;

  /**
   * Optional custom row renderer that provides full control over row rendering
   * If not provided, falls back to default row rendering
   */
  renderRow?: (
    props: {
      className: string;
      key: string;
      style: React.CSSProperties;
      onClick?: () => void;
      children: React.ReactNode;
    },
    data: T
  ) => React.ReactNode;
};

export type FilterViewRowBase = {
  id: string | number;
};

const EmptyRowDefault = () => (
  <div className={style.no_rows}>No rows to display</div>
);

const FilteredView = <T extends FilterViewRowBase>({
  title,
  filters,
  columnsHeaders,
  columns,
  totalWeight,
  pageSize,
  onRowClick,
  rowClassName,
  emptyRowsRender = EmptyRowDefault,
  renderRow,
}: FilteredViewProps<T>) => {
  const { filteredData, usedFilters } = useFilteredViewContext<T>();

  const {
    currentPage,
    onPageChange,
    totalPages,
    data: paginatedData,
  } = usePagination<T>({ data: filteredData, pageSize });

  useEffect(() => {
    // Reset pagination after user apply some filtering
    onPageChange(1);
  }, [usedFilters]);

  return (
    <div>
      <div className={style.view}>
        {Array.isArray(filters) && filters.length > 0 && (
          <div className={style.view_sidebar}>
            <Filters filters={filters} />
          </div>
        )}

        <div className={style.view_body}>
          {title !== undefined && (
            <div className={style.view_header}>
              <p>
                {typeof title === "function"
                  ? title(filteredData.length)
                  : title}
              </p>
            </div>
          )}

          {columnsHeaders && (
            <div
              key="row-header"
              style={{ gridTemplateColumns: `repeat(${totalWeight}, 1fr)` }}
              className={classNames(style.view_columns_headers, style.row)}
            >
              {columnsHeaders.map((col, id) => (
                <div
                  className={classNames(
                    style.column,
                    typeof col !== "string" ? col.className : ""
                  )}
                  key={`col-${String(columns[id].id)}`}
                  style={{ gridColumn: `span ${columns[id].weight}` }}
                >
                  {typeof col !== "string" ? col.label : col}
                </div>
              ))}
            </div>
          )}

          {paginatedData.length === 0 && emptyRowsRender()}
          {paginatedData.map((row) => {
            const rowContent = columns.map((col) => (
              <div
                className={style.column}
                key={`col-${row.id}${String(col.id)}`}
                style={{ gridColumn: `span ${col.weight}` }}
              >
                {col.render(row)}
              </div>
            ));

            const rowProps = {
              className: classNames(style.row, rowClassName),
              key: `row-${row.id}`,
              style: { gridTemplateColumns: `repeat(${totalWeight}, 1fr)` },
              onClick: onRowClick ? () => onRowClick(row) : undefined,
              children: rowContent,
            };

            return renderRow ? renderRow(rowProps, row) : <div {...rowProps} />;
          })}
        </div>
      </div>
      {totalPages > 1 && (
        <ButtonGroup
          pageCount={totalPages}
          onPageChange={onPageChange}
          currentPage={currentPage}
        />
      )}
    </div>
  );
};

export default FilteredView;
