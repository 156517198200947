import type { Icon } from "@blueprintjs/core";
import { Intent, Spinner, SpinnerSize } from "@blueprintjs/core";
import React from "react";
import { IoBanOutline } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import useGetTestExecutionDetails from "./useGetTestExecutionDetails";
import { TestDiff } from "./TestDiff";
import { TestCheck } from "./TestCheck";
import Error from "../../../components/Error/Error";
import type { TestExecution } from "../../../@types/sd/testexecutions";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { TestExecutionStatusPhases } from "../../../@types/sd/testexecutions.d.ts";
import Collapse from "./common/Collapse";
import { BaseTestCollapseTitle } from "./common/TestCollapseTitle/TestCollapseTitle";
import styles from "./common/TestCollapseTitle/TestCollapseTitle.module.css";
import StatusCard from "../../../components/ResourceStatus/StatusCard";
import { useGetTestExecution } from "../../../api/TestExecutionsApi";

type TestExecutionProps = {
  testName?: string;
  textExecutionName?: string;
};

export const TestExecutionDiff = ({
  testName,
  textExecutionName,
}: TestExecutionProps) => {
  const testExecutionDetails = useGetTestExecutionDetails({
    testName,
    executionName: textExecutionName,
  });

  const {
    raw: { executionDetail },
    tests: {
      diff: { controlProps, setControlProps, filteredFindings },
    },
  } = testExecutionDetails;

  if (executionDetail.error) {
    if (executionDetail.error.response?.status === 400) {
      return <Error text={`${textExecutionName} not found`} />;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return (
      <Spinner
        className="flex h-screen"
        intent={Intent.PRIMARY}
        size={SpinnerSize.STANDARD}
      />
    );
  }

  const capturePoints =
    executionDetail.data.results?.trafficDiff?.capturePoints;

  if (!capturePoints || capturePoints.length === 0) return null;

  return (
    <TestDiff
      controlProps={controlProps}
      onControlPropChange={setControlProps}
      executionName={textExecutionName}
      testName={testName}
      executionStatus={executionDetail.data.status.phase}
      executionDetails={executionDetail.data}
      filteredFindings={filteredFindings}
    />
  );
};

export const TestExecutionCheck = ({
  testName,
  textExecutionName,
}: TestExecutionProps) => {
  const testExecutionDetails = useGetTestExecutionDetails({
    testName,
    executionName: textExecutionName,
  });

  const {
    raw: { executionDetail },
  } = testExecutionDetails;

  if (executionDetail.error) {
    if (executionDetail.error.response?.status === 400) {
      return <Error text={`${textExecutionName} not found`} />;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return (
      <Spinner
        className="flex h-screen"
        intent={Intent.PRIMARY}
        size={SpinnerSize.STANDARD}
      />
    );
  }

  return (
    <TestCheck
      executionName={textExecutionName}
      testName={testName}
      executionStatus={executionDetail.data.status.phase}
      checkResults={executionDetail.data?.results?.checks}
      executionDetails={executionDetail.data}
    />
  );
};

type IncompleteTestDetailsProps = {
  execution: TestExecution;
};

const TestCollapseTitle = ({
  execution,
  status,
  icon,
  className,
  isFail,
  opts,
}: {
  execution: TestExecution;
  status: string;
  icon: any;
  className: string;
  isFail: boolean;
  opts: any;
}) => (
  <BaseTestCollapseTitle
    title={`test: ${execution.spec.test}`}
    description={status}
    collapseFuncOpts={opts}
    icon={icon}
    className={className}
    hideCollapse={!isFail}
  />
);

export const IncompleteTestDetails = ({
  execution,
}: IncompleteTestDetailsProps) => {
  let icon: React.ComponentProps<typeof Icon>["icon"];
  let status = "in progress";
  let className = styles.succeeded;

  const { data } = useGetTestExecution(execution.spec.test, execution.name);

  switch (execution.status.phase) {
    case TestExecutionStatusPhases.Pending:
    case TestExecutionStatusPhases.InProgress:
      icon = "symbol-circle";
      status = "pending";
      className = styles.running;
      break;
    case TestExecutionStatusPhases.Canceled:
      icon = <IoBanOutline size={16} />;
      status = "canceled";
      className = styles.canceled;
      break;
    case TestExecutionStatusPhases.Failed:
      icon = <RxCrossCircled className={styles.failed} size={16} />;
      status = "test failed to run";
      className = styles.running;
      break;
    default:
      break;
  }

  const isFail = execution.status.phase === TestExecutionStatusPhases.Failed;

  const errorMessage = React.useMemo(() => {
    if (!data) return null;

    if (data.status.phase !== "failed") {
      return null;
    }
    if (!data.status.finalState?.failed) {
      return null;
    }

    return data.status.finalState.failed.message;
  }, [data]);

  return (
    <Collapse
      title={(opts) => (
        <TestCollapseTitle
          execution={execution}
          status={status}
          icon={icon}
          className={className}
          isFail={isFail}
          opts={opts}
        />
      )}
      show
      defaultOpen={false}
    >
      {isFail && errorMessage && (
        <div className="mb-5 px-4 flex flex-col gap-2">
          <StatusCard
            title="Job Failed"
            description={errorMessage}
            intent={Intent.DANGER}
          />
          <span>
            Check{" "}
            <a
              href={`/testing/tests/${execution.spec.test}/executions/${execution.name}`}
            >
              {execution.name}
            </a>{" "}
            for details.
          </span>
        </div>
      )}
    </Collapse>
  );
};
